import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';

import ProductCartDesktopComponent from './ProductCart.desktop.component';
import ProductCartMobileComponent from './ProductCart.mobile.component';
import { COUNTER_TYPE } from '../../Constants';
import {
  checkJWT,
  checkValidURLImage,
  debounce,
  numberWithCommas
} from '../../Utils/general.utils';
import { ProductDetailImageExample } from '../../Assets/Images';
import {
  addCartPayload,
  subtractCartPayload
} from '../../Redux/Actions/carts.action';

const ProductCartComponent = ({
  cart,
  addToCart,
  deleteCart,
  moveToWishlist
}) => {
  const [quantity, setQuantity] = React.useState(cart?.qty || 1);

  let product_code = get(cart, 'products.code', '');
  let product = get(cart, 'products', {});
  const discount = Number(get(cart, 'discount', 0)).toFixed(2);
  const discountFormat = numberWithCommas(discount);

  let reformatPrice = get(product, 'bprice', 0);
  let reformatMemberPrice = get(product, 'aprice', 0);

  reformatPrice = Number(reformatPrice).toFixed(2);
  reformatMemberPrice = Number(reformatMemberPrice).toFixed(2);

  const productPrice = numberWithCommas(reformatPrice);
  const memberPrice = numberWithCommas(reformatMemberPrice);

  let subTotal = numberWithCommas(
    (Number(quantity) * Number(reformatPrice)).toFixed(2)
  );
  let subTotalAfterDiscount = numberWithCommas(
    (Number(quantity) * Number(reformatPrice) - discount).toFixed(2)
  );

  const productName = get(product, 'name_en', 'Product Name');
  const qtyAmount = get(product, 'qtyAmount', '');
  const productImage = checkValidURLImage(
    get(product, 'image', ProductDetailImageExample)
  );

  const dispatch = useDispatch();

  const verify = React.useCallback(
    debounce(quantity => {
      addToCart(product_code, quantity).catch(console.error);
    }, 2000),
    []
  );

  const addToCartPayload = payload => dispatch(addCartPayload(payload));

  const updateCartsRedux = tempQty => {
    addToCartPayload({
      discount: 0,
      discountId: '',
      products: product,
      qty: Number(tempQty),
      subTotal: Number(Number(tempQty) * Number(product?.bprice)),
      subTotalAfterDiscount: Number(Number(tempQty) * Number(product?.bprice))
    });
  };

  React.useEffect(() => {
    if (cart?.qty !== quantity) {
      verify(quantity);
    }
  }, [quantity]);

  const _counterQuantity = async type => {
    if (type === COUNTER_TYPE.SUBTRACT) {
      if (quantity === 0) return;

      setQuantity(quantity - 1);
      updateCartsRedux(quantity - 1);
      dispatch({ type: 'REMOVE_ITEM' });
      return;
    } else {
      if (quantity + 1 <= qtyAmount) {
        dispatch({ type: 'ADD_ITEM' });

        const newQuantity = Number(quantity) + 1;

        setQuantity(newQuantity);
        updateCartsRedux(newQuantity);
      }
    }
  };

  const _setCounterQuantity = async (type, e) => {
    if (e <= qtyAmount) {
      dispatch({ type: 'SET_ITEM' });
      setQuantity(e);
      updateCartsRedux(e);
    } else {
      setQuantity(qtyAmount);
      updateCartsRedux(qtyAmount);
    }
  };

  const subtractToCartPayload = payload =>
    dispatch(subtractCartPayload(payload));

  const handleDelete = async () => {
    if (!checkJWT()) {
      await deleteCart(product_code);
    } else {
      subtractToCartPayload(cart);
    }
  };

  const handleMoveToWishlist = async () => {
    await moveToWishlist(product_code);
  };

  const cartProps = () => ({
    quantity,
    setQuantity,
    counterQuantity: _counterQuantity,
    setCounterQuantity: _setCounterQuantity,
    cart,
    handleDelete,
    handleMoveToWishlist,
    productName,
    qtyAmount,
    productImage,
    productPrice,
    memberPrice,
    subTotal,
    subTotalAfterDiscount,
    discount: discountFormat
  });

  return (
    <React.Fragment>
      <Row>
        <Col md={12} sm={12} xl={12}>
          <ProductCartDesktopComponent {...cartProps()} />
          <ProductCartMobileComponent {...cartProps()} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ProductCartComponent;
