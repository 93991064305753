import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

import notificationReducer from './Reducers/notification.reducer';
import cartsReducer from './Reducers/carts.reducer';
import filterAppellationReducer from './Reducers/filterAppellation.reducer';
import filterCountryReducer from './Reducers/filterCountry.reducer';
import filterCategoryReducer from './Reducers/filterCategory.reducer';
import filterRegionReducer from './Reducers/filterRegion.reducer';
import filterNewDataReducer from './Reducers/filterNewData.reducer';
import showHideReducer from './Reducers/showHide.reducer';
import appellationDataReducer from './Reducers/appellationData.reducer';
import filterWebsiteListingReducer from './Reducers/filterWebsiteListing.reducer';
import selectedFilterValueReducer from './Reducers/selectedFilterValue.reducer';
import filterSearchReducer from './Reducers/filterSearch.reducer';
import filterActiveReducer from './Reducers/filterActive.reducer';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['cartsContainer', 'total']
};
const persistConfig2 = {
  key: 'root2',
  storage: storage,
  whitelist: [
    'filterCountry',
    'filterCategory',
    'filterRegion',
    'filterNewData'
  ]
};

const persistFilterNewData = {
  key: 'filterNewData',
  storage: storage,
  whitelist: ['filterNewData']
};

const persistFilterAppellation = {
  key: 'filterAppellation',
  storage: storage,
  whitelist: ['filterAppellation']
};

const persistAppellationData = {
  key: 'appellationData',
  storage: storage,
  whitelist: ['appellationData']
};

const persistFilterWebsiteListing = {
  key: 'filterWebsiteListing',
  storage: storage,
  whitelist: ['filterWebsiteListing']
};

const showHidePersistConfig = {
  key: 'showHideReducer',
  storage: storage,
  blacklist: []
};

const selectedFilterValuePersistConfig = {
  key: 'selectedFilterValueReducer',
  storage: storage,
  blacklist: []
};

const filterSearchPersistConfig = {
  key: 'filterSearchReducer',
  storage: storage,
  blacklist: []
};

const filterActivePersistConfig = {
  key: 'filterActiveReducer',
  storage: storage,
  blacklist: []
};

const rootReducer = combineReducers({
  notificationReducer: persistReducer(persistConfig, notificationReducer),
  cartsReducer: persistReducer(persistConfig, cartsReducer),
  filterAppellationReducer: persistReducer(
    persistFilterAppellation,
    filterAppellationReducer
  ),
  filterCountryReducer: persistReducer(persistConfig2, filterCountryReducer),
  filterCategoryReducer: persistReducer(persistConfig2, filterCategoryReducer),
  filterRegionReducer: persistReducer(persistConfig2, filterRegionReducer),
  filterNewDataReducer: persistReducer(
    persistFilterNewData,
    filterNewDataReducer
  ),
  appellationDataReducer: persistReducer(
    persistAppellationData,
    appellationDataReducer
  ),
  showHideReducer: persistReducer(showHidePersistConfig, showHideReducer),
  selectedFilterValueReducer: persistReducer(
    selectedFilterValuePersistConfig,
    selectedFilterValueReducer
  ),
  filterWebsiteListingReducer: persistReducer(
    persistFilterWebsiteListing,
    filterWebsiteListingReducer
  ),
  filterSearchReducer: persistReducer(
    filterSearchPersistConfig,
    filterSearchReducer
  ),
  filterActiveReducer: persistReducer(
    filterActivePersistConfig,
    filterActiveReducer
  )
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
