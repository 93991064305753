import React from 'react';
import { useTranslation } from 'react-i18next';

import { servicesRequest } from '../../Utils';
import { CONTENT_URL } from '../../Utils/serviceUrl.utils';
import {
  Container,
  Content,
  Image,
  Title
} from '../BlogDetail/BlogDetail.styled';
import { getTitle, getContent } from '../../Utils/general.utils';

const ContentContainer = ({ type }) => {
  const [blog, setBlog] = React.useState(null);
  const { i18n } = useTranslation();

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(CONTENT_URL + '/detail', {
        params: {
          type
        }
      });

      setBlog(data);
    } catch (e) {}
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  return (
    <Container>
      <Title className="mb-4">
        {getTitle(blog?.title, blog?.title_ch, i18n.language)}
      </Title>
      <Image src={blog?.thumbnail} className="img-fluid" />
      <Content
        className="mt-4"
        dangerouslySetInnerHTML={{ __html: getContent(blog, i18n.language) }}
      />
    </Container>
  );
};

export default ContentContainer;
