import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import {
  StyledSlider,
  ColRight,
  SliderContainer,
  TextFieldStyled,
  ButtonStyled,
  InputPrefixWrapper,
  SmalLabel,
  FlexContainer,
  FlexItem,
  FlexInputWrapper,
  SmallLabel,
  TextFieldStyled2
} from './PriceRange.styled';


const PriceRangeComponent = ({ minPrice, maxPrice, rangeSelector }) => {
  const [value, setValue] = React.useState([minPrice, maxPrice]);
  const [minPrice2, setMinPrice2] = React.useState(minPrice);
  const [maxPrice2, setMaxPrice2] = React.useState(maxPrice);
  const setRangeSelector = (event, newValue) => {
    setValue(newValue);
  };
  const setRangeSelectorComitted = (event, newValue) => {
    rangeSelector(newValue);
    setMinPrice2(newValue[0])
    setMaxPrice2(newValue[1])
  };
  const handleInputChangeMin = event => {
    setMinPrice2(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleInputChangeMax = event => {
    setMaxPrice2(event.target.value === '' ? '' : Number(event.target.value));
  };

  const { t, i18n } = useTranslation();

  const submitRange = () => {
    const sum = [minPrice2, maxPrice2];
    setValue(sum);
    rangeSelector(sum);
  };

  const maximumPrice = 100000

  return (
    <div className="mt-2">
      <SliderContainer>
        <StyledSlider
          value={value}
          min={0}
          max={maximumPrice}
          onChange={setRangeSelector}
          onChangeCommitted={setRangeSelectorComitted}
        />
      </SliderContainer>
      <Row className="pt-2">
        <Col md={6}>
          <InputPrefixWrapper>
            <SmalLabel>HK$</SmalLabel>
            <div>
              <TextFieldStyled
                value={minPrice2}
                disableunderline="true"
                size="small"
                onChange={handleInputChangeMin}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: maximumPrice,
                  type: 'number',
                  'aria-labelledby': 'input-slider'
                }}
              />
            </div>
          </InputPrefixWrapper>
        </Col>
        <Col md={6}>
          <InputPrefixWrapper>
            <SmalLabel>HK$</SmalLabel>
            <div>
              <TextFieldStyled
                value={maxPrice2}
                size="small"
                onChange={handleInputChangeMax}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: maximumPrice,
                  type: 'number',
                  'aria-labelledby': 'input-slider'
                }}
              />
            </div>
          </InputPrefixWrapper>
        </Col>
      </Row>
      <Row className='px-3 pt-3'>
        <ButtonStyled onClick={() => submitRange()}>{t('search')}</ButtonStyled>
      </Row>
    </div>
  );
};

export default PriceRangeComponent;
