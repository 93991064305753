import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
    CustomCollapse,
    CustomPanel,
} from '../Product.styled'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const CollapsMenu = ({ panels }) => {
    const navigate = useNavigate();

    const CustomExpandIcon = ({ isActive }) => {
        return isActive ? '' : '';
    };

    const handlePanelClick = (key) => {
        if (key === '1') {
            if (window.location.pathname.includes('/search')) {
                navigate('/product');
            }
        }
    };

    const defaultActiveKeys = panels.map(panel => panel.key);

    return (
        <CustomCollapse
            expandIconPosition="end"
            expandIcon={({ isActive }) => <CustomExpandIcon isActive={isActive} />}
            defaultActiveKey={defaultActiveKeys}
            bordered={false}
            collapsible='icon'
        >
            {panels.map((panel, index) => (
                <CustomPanel key={panel.key} header={panel.header} onClick={() => handlePanelClick(panel.key)}>
                    {panel.content}
                </CustomPanel>
            ))}
        </CustomCollapse>
    );
};

export default CollapsMenu;
