import {
  FILTER_NEW_DATA_PAYLOAD
} from './action.types';

export const filterNewDataPayload = payload => dispatch => {
  dispatch({
    type: FILTER_NEW_DATA_PAYLOAD,
    payload: payload
  });
};

