import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { servicesRequest } from '../../Utils';
import {
  CATEGORY_URL,
  CONTENT_URL,
  PRODUCT_CART_URL,
  PRODUCT_URL,
  PRODUCT_WISHLIST_URL
} from '../../Utils/serviceUrl.utils';
import ProductComponent from './Product.component';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import {
  addCart,
  addCartPayload,
  setDefaultCart
} from '../../Redux/Actions/carts.action';
import { checkJWT } from '../../Utils/general.utils';

const ProductContainer = ({ categoryCode = '', categoryRegion = '' }) => {
  const [products, setProducts] = React.useState([]);
  const [wishlists, setWishlists] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [item, setItem] = React.useState(36);
  const [view, setView] = React.useState('grid');
  const [carts, setCarts] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    page: '1',
    total_page: '1',
    total_item: '1',
    itemperpage: '20'
  });
  const [isLoading, setIsLoading] = React.useState(true);

  const [yearFilter, setYearFilter] = React.useState('');
  const [sizeFilter, setSizeFilter] = React.useState('');

  const [yearCategories, setYearCategories] = React.useState([]);

  const [minPrice, setMinPrice] = React.useState(0);
  const [maxPrice, setMaxPrice] = React.useState(100000);

  const [bannerURL, setBannerURL] = React.useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const addToCart = (amount = 1) => dispatch(addCart(amount));
  const addToCartPayload = payload => dispatch(addCartPayload(payload));
  const setDefaultAmountCart = (amount = 1) => dispatch(setDefaultCart(amount));

  const { filterAppellation: filterAppellation } = useSelector(
    state => state.filterAppellationReducer
  );
  const { filterCountry: filterCountry } = useSelector(
    state => state.filterCountryReducer
  );

  const { filterCategory: filterCategory } = useSelector(
    state => state.filterCategoryReducer
  );
  const { filterRegion: filterRegion } = useSelector(
    state => state.filterRegionReducer
  );
  const { filterNewData: filterNewData } = useSelector(
    state => state.filterNewDataReducer
  );
  const { filterWebsiteListing: filterWebsiteListing } = useSelector(
    state => state.filterWebsiteListingReducer
  );

  const { filterSearch: filterSearch } = useSelector(
    state => state.filterSearchReducer
  );

  let filterCountryDefaultCondition = filterCountry;

  if (
    (filterCountryDefaultCondition === undefined ||
      filterCountryDefaultCondition === '') &&
    (filterWebsiteListing === undefined || filterWebsiteListing === '')
  ) {
    filterCountryDefaultCondition = 'France';
  }

  const fetching = async () => {
    try {
      setIsLoading(true);
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.post(PRODUCT_URL, {
        itemCode: '',
        categoryCode: filterCategory,
        websiteListing: filterWebsiteListing,
        itemName: filterSearch,
        itemPerPage: item,
        pageCount: page,
        minPrice: minPrice,
        maxPrice: maxPrice,
        categoryYear: yearFilter,
        categorySize: sizeFilter,
        categoryRegion: categoryRegion,
        region: filterRegion,
        country: filterCountryDefaultCondition,
        onlineStore: '1',
        appellation: filterAppellation,
        sortByDate: filterNewData ? filterNewData : true
      });

      setProducts(data.products);
      setPagination(data.pagination);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBanner = async () => {
    try {
      const request = await servicesRequest();
      let {
        data: {
          data: { thumbnail }
        }
      } = await request.get(CONTENT_URL + '/detail', {
        params: {
          type: 'PRODUCT_BANNER'
        }
      });

      thumbnail = JSON.parse(thumbnail);
      setBannerURL(thumbnail);
    } catch (e) {}
  };

  const fetchingWishlist = async () => {
    try {
      if (!checkJWT()) {
        const authorizedRequest = await servicesRequest();
        const {
          data: { data }
        } = await authorizedRequest.get(PRODUCT_WISHLIST_URL);

        setWishlists(data);
      }
    } catch (error) {
      showNotification(
        'Sorry, we unable to fetch your wishlist. Please try again'
      );
    }
  };
  const fetchingCarts = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.get(PRODUCT_CART_URL + '/products');

      setDefaultAmountCart(data.carts.length);
      const dataCode = data?.carts.map(item => item.products.code);
      setCarts(dataCode);
    } catch (error) {
      showNotification('Sorry, we unable to fetch your Cart. Please try again');
    }
  };

  const fetchingYearCategories = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.get(CATEGORY_URL + '/year');
      setYearCategories(data);
    } catch (error) {
      showNotification(
        'Sorry, we unable to fetch year category. Please try again'
      );
    }
  };

  const wishlistAction = async id => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.post(PRODUCT_WISHLIST_URL, {
        product: id
      });
      setWishlists(data);
    } catch (error) {
      showNotification('Something went wrong. Try again later');
    }
  };

  const cartAction = async product => {
    const { code } = product;

    try {
      if (!checkJWT()) {
        const authorizedRequest = await servicesRequest();
        await authorizedRequest.post(PRODUCT_CART_URL, {
          product_code: code,
          qty: 1
        });
      } else {
        addToCartPayload({
          discount: 0,
          discountId: '',
          products: product,
          qty: 1,
          subTotal: Number(product?.bprice),
          subTotalAfterDiscount: Number(product?.bprice)
        });
      }

      addToCart();
      showNotification(
        <>
          <div>{t('successfully-added-cart')}</div>
          <a href="/cart">{t('go-to-checkout')}</a>
        </>
      );
    } catch (e) {
      showNotification(
        'Sorry, unable to add product to cart. Please try again'
      );
    }
  };

  const handlePriceFilter = price => {
    setMaxPrice(price.max);
    setMinPrice(price.min);
  };

  const handleYearFilter = year => {
    setYearFilter(year);
  };

  const rangeSelector = data => {
    setMaxPrice(data[1]);
    setMinPrice(data[0]);
  };

  const props = {
    products,
    setProducts,
    wishlists,
    setWishlists,
    page,
    setPage,
    item,
    setItem,
    setView,
    view,
    pagination,
    setPagination,
    wishlistAction,
    cartAction,
    carts,
    handlePriceFilter,
    minPrice,
    maxPrice,
    yearCategories,
    handleYearFilter,
    yearFilter,
    setSizeFilter,
    sizeFilter,
    rangeSelector,
    bannerURL,
    filterCategory,
    isLoading
  };

  React.useEffect(() => {
    fetchingYearCategories().catch(console.error);
    fetchBanner().catch(console.error);
  }, []);

  React.useEffect(() => {
    fetching().catch(console.error);
    localStorage.getItem('token') &&
      fetchingWishlist().catch(console.error) &&
      fetchingCarts().catch(console.error);
  }, [
    page,
    item,
    filterAppellation,
    filterWebsiteListing,
    categoryCode,
    yearFilter,
    sizeFilter,
    maxPrice,
    minPrice,
    filterCountry,
    filterCategory,
    filterRegion,
    filterNewData,
    categoryRegion,
    filterSearch
  ]);

  return (
    <>
      <ProductComponent {...props} />
    </>
  );
};

export default ProductContainer;
