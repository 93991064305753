import React from 'react';
import Slider from 'react-slick';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { servicesRequest } from '../../../Utils';
import { PRODUCT_CART_URL, PRODUCT_URL } from '../../../Utils/serviceUrl.utils';
import { ProductCard } from '../../../Components';
import {
  addCart,
  addCartPayload,
  setDefaultCart
} from '../../../Redux/Actions/carts.action';
import { checkJWT } from '../../../Utils/general.utils';
import { showNotification as showNotificationRedux } from '../../../Redux/Actions/notification.action';

export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1
      }
    }
  ]
};

const ProductComponent = () => {
  const [products, setProducts] = React.useState([]);
  const [carts, setCarts] = React.useState([]);

  const { t } = useTranslation();

  const page = 1;
  const item = 12;

  const fetching = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.post(PRODUCT_URL, {
        itemCode: '',
        categoryCode: '',
        itemPerPage: item,
        pageCount: page,
        onlineStore: '1'
      });

      setProducts(data.products);
    } catch (error) {}
  };
  const fetchingCarts = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.get(PRODUCT_CART_URL + '/products');

      setDefaultAmountCart(data.carts.length);
      const dataCode = data?.carts.map(item => item.products.code);
      setCarts(dataCode);
    } catch (error) {
      console.error(error);
      showNotification('Sorry, we unable to fetch your Cart. Please try again');
    }
  };

  React.useEffect(() => {
    fetching().catch(console.error);
    localStorage.getItem('token') && fetchingCarts().catch(console.error);
  }, []);

  const dispatch = useDispatch();

  const addToCart = (amount = 1) => dispatch(addCart(amount));
  const addToCartPayload = payload => dispatch(addCartPayload(payload));
  const setDefaultAmountCart = (amount = 1) => dispatch(setDefaultCart(amount));

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const cartAction = async product => {
    const { code } = product;

    try {
      if (!checkJWT()) {
        const authorizedRequest = await servicesRequest();
        await authorizedRequest.post(PRODUCT_CART_URL, {
          product_code: code,
          qty: 1
        });
      } else {
        addToCartPayload({
          discount: 0,
          discountId: '',
          products: product,
          qty: 1,
          subTotal: Number(product?.bprice),
          subTotalAfterDiscount: Number(product?.bprice)
        });
      }

      addToCart();
      showNotification(
        <>
          <div>{t('successfully-added-cart')}</div>
          <a href="/cart">{t('go-to-checkout')}</a>
        </>
      );
      if (localStorage.getItem('token')) {
        await fetchingCarts();
      }
    } catch (e) {
      console.log(e);
      showNotification(
        'Sorry, unable to add product to cart. Please try again'
      );
    }
  };

  return (
    <div>
      {products?.length === 0 ? (
        <ShimmerSimpleGallery row={1} col={4} imageHeight={200} caption />
      ) : (
        <Slider {...settings}>
          {products.map((item, index) => {
            return (
              <div key={index} className="px-1">
                <ProductCard
                  cartAction={cartAction}
                  data={item}
                  carts={carts}
                  isWithWishlistButton={true}
                />
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
};

export default ProductComponent;
