import {
  FILTER_COUNTRY_PAYLOAD
} from './action.types';

export const filterCountryPayload = payload => dispatch => {
  dispatch({
    type: FILTER_COUNTRY_PAYLOAD,
    payload: payload
  });
};

