import React, { useState } from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { filterAppellationPayload } from '../../../Redux/Actions/filterAppellation.action';
import { filterCountryPayload } from '../../../Redux/Actions/filterCountry.action';
import { filterCategoryPayload } from '../../../Redux/Actions/filterCategory.action';
import { filterRegionPayload } from '../../../Redux/Actions/filterRegion.action';
import { filterWebsiteListingPayload } from '../../../Redux/Actions/filterWebsiteListing.action';
import { filterSearchPayload } from '../../../Redux/Actions/filterSearch.action';
import { appellationDataPayload } from '../../../Redux/Actions/appellationData.action';
import { filterActivePayload } from '../../../Redux/Actions/filterActive.action';
import { showHidePayload } from '../../../Redux/Actions/showHide.action';

import './FilterMenu.styled.scss';
import menuData from './menuData.json';

const FilterMenu = () => {
  const [country, setCountry] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);

  const rootSubmenuKeys = menuData.country_list
    .filter(item => item.id && item.des !== null)
    .map(item => item.id);
  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => !openKeys.includes(key));
    if (!rootSubmenuKeys.includes(latestOpenKey)) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const filterAppellation = data => dispatch(filterAppellationPayload(data));
  const filterCountry = data => dispatch(filterCountryPayload(data));
  const filterCategory = data => dispatch(filterCategoryPayload(data));
  const filterRegion = data => dispatch(filterRegionPayload(data));
  const filterWebsiteListing = data =>
    dispatch(filterWebsiteListingPayload(data));
  const filterSearch = data => dispatch(filterSearchPayload(data));
  const appellationData = data => dispatch(appellationDataPayload(data));
  const filterActive = data => dispatch(filterActivePayload(data));
  const showHideSidebar = data => dispatch(showHidePayload(data));

  const { filterActive: filterActiveRedux } = useSelector(
    state => state.filterActiveReducer
  );

  const handleFilterCountry = id => {
    filterRegion('');
    filterAppellation('');
    filterCategory('');
    appellationData('');
    setTimeout(() => filterWebsiteListing(id), 500);
  };

  const handleFilterGeneral = (id, submenu) => {
    clearForWebsiteListing();
    filterWebsiteListing(id);
    appellationData(submenu);
  };

  const handleFilterRegion = id => {
    filterWebsiteListing('');
    filterCountry('');
    filterAppellation('');
    filterCategory(id);
  };

  const handleFilterAppellation = id => {
    filterAppellation(id);
  };

  const handleClick = (id, category, submenu, e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    showHideSidebar(false);
    if (id === 'france') {
      setActiveMenu(null);
    } else if (id === 'all') {
      removeAllFilter();
    } else {
      filterActive('SIDE_FILTER');
      filterSearch('');
      if (category === 'general') {
        handleFilterGeneral(id, submenu);
      } else if (category === 'country') {
        handleFilterCountry(id);
        setCountry(id);
      } else if (category === 'appellation') {
        handleFilterAppellation(id);
      } else if (category === 'region') {
        handleFilterRegion(id);
      }
      setActiveMenu(id);
    }
  };

  const removeAllFilter = () => {
    filterCountry('');
    filterCategory('');
    filterAppellation('');
    filterRegion('');
    appellationData('');
    setOpenKeys([]);
    filterWebsiteListing('');
    filterSearch('');
    filterActive('SIDE_FILTER');
    setActiveMenu('all');
  };

  const clearForWebsiteListing = () => {
    filterCountry('');
    filterCategory('');
    filterAppellation('');
    filterRegion('');
    filterSearch('');
  };

  const inlineIndentStyle = {
    paddingLeft: 24
  };

  return (
    <Menu
      mode="inline"
      theme="light"
      inlineIndent={12}
      className="menu-no-border"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      selectedKeys={
        (activeMenu === 'all' && filterActiveRedux === 'SIDE_FILTER') ||
        (activeMenu !== null && filterActiveRedux === 'SIDE_FILTER')
          ? [activeMenu]
          : []
      }>
      {menuData.country_list.map(item => {
        return (
          <Menu.Item
            key={item.id}
            style={item.child === 'france' ? inlineIndentStyle : null}
            onClick={e => handleClick(item.id, item.category, item.submenu, e)}>
            {i18n.language === 'en' ? item.name : item.name_ch}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default FilterMenu;
