import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ImFacebook, ImWhatsapp } from 'react-icons/im';
import { AiFillInstagram } from 'react-icons/ai';

import { ROUTE } from '../../Constants';
import {
  Container,
  FooterContainer,
  FooterLink,
  FooterLinkCol,
  FooterParagraph,
  SocialMedia,
  StyledHrefWa
} from './Footer.styled';
import { DisplayFlexRight, StyledHref } from '../../Assets/GlobalStyled';
import Icon from '../Icon';
import { servicesRequest } from '../../Utils';
import { CONTENT_URL } from '../../Utils/serviceUrl.utils';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

const iconWaStyle = {
  size: '1.8em'
};
const iconFooterStyle = {
  size: '28px'
};
const iconIgStyle = {
  size: '30px'
};

const FooterDesktop = () => {
  const [links, setLinks] = React.useState({});
  const { t, i18n } = useTranslation();

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(CONTENT_URL + '/detail', {
        params: {
          type: 'SOCIAL_MEDIA'
        }
      });

      setLinks(JSON.parse(data?.content));
    } catch (e) {}
  };

  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  return (
    <div className='footer-desktop'>
      <Container>
        <StyledHrefWa href="https://wa.link/1lxl2c" target="_blank">
          <Icon style={iconWaStyle} IconShow={ImWhatsapp} />
        </StyledHrefWa>
        <Row>
          <Col>
            <FooterParagraph>
              <span dangerouslySetInnerHTML={{
                __html: t('delivery-description')
                  .replace(
                    'over HKD 1,500 or 6 standard bottles',
                   '<u><strong>over HKD 1,500 or 6 standard bottles</strong></u>'
                  )
              }}
              ></span>
            </FooterParagraph>
          </Col>
          <Col>
            <Row>
              <Col xs={6} md={4}>
                <FooterLinkCol xl={12}>
                  <FooterLink href={`/${ROUTE.faq}`}>{t('faq')}</FooterLink>
                </FooterLinkCol>
                <FooterLinkCol>
                  <FooterLink href={`/${ROUTE.news}`}>{t('news')}</FooterLink>
                </FooterLinkCol>
                <FooterLinkCol>
                  <FooterLink href={`/${ROUTE.images}`}>{t('gallery')}</FooterLink>
                </FooterLinkCol>
                <FooterLinkCol>
                  <FooterLink href={`/${ROUTE.contactUs}`}>{t('contact')}</FooterLink>
                </FooterLinkCol>
              </Col>
              <Col xs={6} md={4}>
                <FooterLinkCol xl={12}>
                  <FooterLink href="term-of-use">{t('terms-condition')}</FooterLink>
                </FooterLinkCol>
                <FooterLinkCol xl={12}>
                  <FooterLink href="privacy-policy">{t('privacy')}</FooterLink>
                </FooterLinkCol>
              </Col>
            </Row>
          </Col>
          <Col md={2} lg={2} xl={2}>
            <DisplayFlexRight className="mb-3">
              <SocialMedia>
                <StyledHref href={links?.facebook} target="blank">
                  <Icon style={iconFooterStyle} IconShow={ImFacebook} />
                </StyledHref>
              </SocialMedia>
              <SocialMedia>
                <StyledHref href={links?.instagram} target="blank">
                  <Icon style={iconIgStyle} IconShow={AiFillInstagram} />
                </StyledHref>
              </SocialMedia>
            </DisplayFlexRight>
          </Col>
        </Row>
      </Container>
      <FooterContainer className="py-2">
        <FooterParagraph className="text-center">
          <span>
            © {new Date().getFullYear()} {t('footer-copyright')}
          </span>
        </FooterParagraph>
      </FooterContainer>
    </div>
  );
};

export { FooterDesktop }
