export const BASE_URL = process.env.REACT_APP_API_URL;

export const CONTACT_URL = `${BASE_URL}contact`;
export const CONTACT_ENQUIRIES_URL = `${CONTACT_URL}/enquiries`;
export const SIGN_UP_URL = `${BASE_URL}auth/sign-up`;
export const SIGN_IN_URL = `${BASE_URL}auth/sign-in`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}auth/forgot-password`;
export const AUTH_OTP_URL = `${BASE_URL}auth/otp`;
export const RESET_PASSWORD_URL = `${BASE_URL}auth/reset-password`;
export const UPDATE_PASSWORD_URL = `${BASE_URL}auth/update-password`;
export const PRODUCT_URL = `${BASE_URL}product`;
export const PRODUCT_WISHLIST_URL = `${BASE_URL}product-wishlist`;
export const PRODUCT_WISHLIST_WITH_PRODUCT_URL = `${PRODUCT_WISHLIST_URL}/products`;
export const CATEGORY_URL = `${BASE_URL}category`;
export const BLOG_URL = `${BASE_URL}blog`;
export const BLOG_DETAIL_URL = `${BLOG_URL}/detail/`;
export const NEWS_URL = `${BASE_URL}news`;
export const NEWS_DETAIL_URL = `${NEWS_URL}/detail/`;
export const GALLERY_URL = `${BASE_URL}gallery`;
export const GALLERY_DETAIL_URL = `${GALLERY_URL}/detail/`;
export const FAQ_URL = `${BASE_URL}faq`;
export const PRODUCT_CART_URL = `${BASE_URL}cart`;
export const EVENT_URL = `${BASE_URL}event`;
export const EVENT_DETAIL_URL = `${EVENT_URL}/detail/`;
export const EVENT_REGISTER_URL = `${EVENT_URL}/register/`;
export const DISCOUNT_URL = `${BASE_URL}discount`;
export const ACCOUNT_URL = `${BASE_URL}account`;
export const PROFILE_URL = `${ACCOUNT_URL}/profile`;
export const ADDRESS_URL = `${ACCOUNT_URL}/address`;
export const DEFAULT_ADDRESS_URL = `${ADDRESS_URL}/default-address`;
export const PURCHASE_URL = `${BASE_URL}purchase`;
export const CONTENT_URL = `${BASE_URL}content`;
export const BURGUNDY_URL = `${BASE_URL}burgundy`;
export const SHIPMENT_URL = `${BASE_URL}shipment`;
export const PAYMENT_METHOD_URL = `${BASE_URL}payment/payment-method`;
