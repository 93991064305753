import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  ContentLabel,
  ContentLabelBold
} from '../ProductCard/ProductCard.styled';
import './WineList.styled.scss';

const WineListComponent = ({ data }) => {
  const { t } = useTranslation();

  const mapping = () => {
    return data?.map(item => {
      return {
        ...item,
        key: item.id
      };
    });
  };

  const columns = [
    {
      title: `${t('code-no')}`,
      dataIndex: 'code',
      key: 'code',
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('price')}`,
      dataIndex: 'aprice',
      key: 'aprice',
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('member-price')}`,
      dataIndex: 'bprice',
      key: 'bprice',
      render: text => <ContentLabelBold>{text}</ContentLabelBold>
    },
    {
      title: `${t('vintage')}`,
      dataIndex: 'vintage',
      key: 'vintage',
      render: text => <ContentLabelBold>{text}</ContentLabelBold>
    },
    {
      title: `${t('description')}`,
      dataIndex: 'name_en',
      key: 'name_en',
      render: text => <ContentLabelBold>{text ? text : '-'}</ContentLabelBold>
    },
    {
      title: `${t('qty')}`,
      key: 'qty',
      dataIndex: 'qty',
      render: text => (
        <ContentLabel>
          {JSON.parse(text).totalQty > 0 ? JSON.parse(text).totalQty : 0}
        </ContentLabel>
      )
    },
    {
      title: `${t('size-ml')}`,
      key: 'size',
      dataIndex: 'size',
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('country')}`,
      key: 'country',
      dataIndex: 'country',
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('region')}`,
      key: 'region',
      dataIndex: 'region',
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('appellation')}`,
      dataIndex: 'appellation',
      key: 'appellation',
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('rating')}`,
      key: 'rating',
      dataIndex: 'rating',
      render: text => <ContentLabel>{text}</ContentLabel>
    },
    {
      title: `${t('category')}`,
      key: 'category_label',
      dataIndex: 'category_label',
      render: text => <ContentLabel>{text}</ContentLabel>
    }
  ];
  return (
    <>
      <Table
        className="product-tabel pt-3"
        columns={columns}
        dataSource={mapping()}
        pagination={false}
        size="small"
        rowKey="code"
      />
    </>
  );
};

export default WineListComponent;
