import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import propTypes from 'prop-types';

import {
  ContinueShoppingButton,
  OrderSummary,
  PlaceOrderButton,
  SummaryLabel,
  SummaryLabelBold,
  SummaryValue,
  SummaryValueBold
} from '../Cart.styled';
import { checkJWT, numberWithCommas } from '../../../Utils/general.utils';
import { ROUTE } from '../../../Constants';
import { useSelector } from 'react-redux';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

const RightSideComponent = props => {
  const {
    isPlaceOrder,
    setIsPlaceOrder,
    subTotal,
    discount,
    autoDiscount,
    deliveryFee
  } = props;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  let reduxState = useSelector(state => state.cartsReducer);
  let { cartsContainer: tempLocalCartsContainer } = reduxState;

  tempLocalCartsContainer = JSON.parse(tempLocalCartsContainer);

  let discountValue = get(discount, 'value', 0);
  if (discount) {
    if (discount?.type === 'PERCENTAGE') {
      discountValue = (Number(discount?.value) / 100) * Number(subTotal);
    } else {
      discountValue = Number(discount?.value);
    }
  }

  let finalSubTotal = checkJWT() ? tempLocalCartsContainer?.subTotal : subTotal;

  const calculateDiscount = discountValue + autoDiscount;
  const grandTotal = checkJWT()
    ? tempLocalCartsContainer?.subTotal
    : subTotal - calculateDiscount + deliveryFee;

  return (
    <React.Fragment>
      <OrderSummary>{t('order-sumary')}</OrderSummary>
      <Row className="mt-3 gy-2">
        <Col md={8} xs={8}>
          <SummaryLabel>{t('sub-total')}</SummaryLabel>
        </Col>
        <Col md={4} xs={4}>
          <SummaryValue>
            ${numberWithCommas(Number(finalSubTotal).toFixed(2))}
          </SummaryValue>
        </Col>

        <Col md={8} xs={8}>
          <SummaryLabel>{t('discount')}</SummaryLabel>
        </Col>
        <Col md={4} xs={4}>
          <SummaryValue>
            -${numberWithCommas(Number(calculateDiscount))}
          </SummaryValue>
        </Col>

        <Col md={8} xs={8}>
          <SummaryLabel>{t('delivery-fee')}</SummaryLabel>
        </Col>
        <Col md={4} xs={4}>
          <SummaryValue>${numberWithCommas(Number(deliveryFee))}</SummaryValue>
        </Col>

        <Col md={8} xs={8}>
          <SummaryLabelBold>{t('grand-total')}</SummaryLabelBold>
        </Col>
        <Col md={4} xs={4}>
          <SummaryValueBold>
            ${numberWithCommas(Number(grandTotal).toFixed(2))}
          </SummaryValueBold>
        </Col>

        {!isPlaceOrder && (
          <React.Fragment>
            <Col md={6}>
              <PlaceOrderButton
                onClick={() => {
                  if (!checkJWT()) {
                    setIsPlaceOrder(true);
                  } else {
                    navigate('/' + ROUTE.signIn + '/carts');
                  }
                }}>
                {t('place-order')}
              </PlaceOrderButton>
            </Col>
            <Col md={6}>
              <ContinueShoppingButton
                onClick={() => navigate('/' + ROUTE.product)}>
                {t('continue-shoping')}
              </ContinueShoppingButton>
            </Col>
          </React.Fragment>
        )}
      </Row>
    </React.Fragment>
  );
};

RightSideComponent.propTypes = {
  isPlaceOrder: propTypes.bool,
  setIsPlaceOrder: propTypes.func
};

RightSideComponent.defaultProps = {
  isPlaceOrder: false,
  setIsPlaceOrder: () => {}
};

export default RightSideComponent;
