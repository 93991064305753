import styled, { css } from 'styled-components';
import { Col } from 'react-bootstrap';

import { DisplayFlex } from '../../Assets/GlobalStyled';
import { mobile } from '../../Assets/layout';

export const Container = styled.div`
  padding: 2vw;

  ${mobile(css`
    padding: 4vw;
  `)};
`;

export const Title = styled.h1`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 44px;
  color: #cda257;

  ${mobile(css`
    text-align: center;
  `)};
`;

export const ColTitle = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #626262;
  margin-bottom: 0.5vh;
`;

export const CartContainer = styled(Col)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 2vh;

  ${mobile(css`
    display: none;
  `)};
`;

export const OrderSummary = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #13101e;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 0.5vh;
`;

export const SummaryLabel = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #626262;
`;

export const SummaryValue = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #171520;
  text-align: right;
`;

export const SummaryLabelBold = styled(SummaryLabel)`
  font-weight: bold;
  color: #171520;
`;

export const SummaryValueBold = styled(SummaryValue)`
  font-weight: bold;
  color: #171520;
`;

export const PlaceOrderButton = styled.button`
  border: 2px solid #cda257;
  background: #cda257;
  border-radius: 8px;
  color: #ffffff;
  padding: 1.5vh 2vw;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
`;

export const ContinueShoppingButton = styled(PlaceOrderButton)`
  background: #ffffff;
  border: 2px solid #cda257;
  border-radius: 8px;
  color: #cda257;
`;

export const InputCouponContainer = styled(DisplayFlex)`
  background: #f1f1f1;
  width: 25%;
`;

export const InputCouponInput = styled.input`
  border: none;
  background: #f1f1f1;
  padding: 1vh;
  font-size: 14px;
  font-family: 'Inter', sans-serif;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #626262;
  }

  :-ms-input-placeholder {
    color: #626262;
  }
`;

export const InputCouponButton = styled.button`
  text-transform: uppercase;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #cda257;
  background: #f1f1f1;
  border: none;
  padding: 1vh;
`;

export const PaymentItem = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  color: #171520;
  text-align: center;
  padding: 2vh;
  background-color: ${props =>
    props.type ? 'rgb(205, 162, 87, 0.08)' : '#ffffff'};
  border: ${props =>
    props.type ? '1px solid rgb(205, 162, 87, 0.08)' : '1px solid #e3e3e3'};
  height: 8.5em !important;
`;

export const PaymentItemTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #171520;
  margin-top: 1vh;
`;

export const PaymentItemDescription = styled.p`
  margin: 0;
  font-size: 10px;
  text-align: center;
  color: #171520;
`;

export const DeliveryPrice = styled.p`
  color: #cda257;
`;

export const RoundedButton = styled.div`
  height: 2.5vh;
  width: 2.5vh;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${props => (props?.type ? '#CDA257' : '#FFFFFF')};
  border: ${props => (props?.type ? '2px solid #CDA257' : '2px solid #626262')};
  outline: ${props => (props?.type ? '1px solid #cda257' : 'none')};
  outline-offset: 1px;
`;

export const CouponError = styled.p`
  color: red;
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
`;

export const DeliveryContainer = styled.div`
  border: 1px solid #efeee8;
  border-radius: 12px;
  color: #171520;
  padding: 2vh;
  background: #efeee8;
`;

export const ProfileAddressContainer = styled.div`
  border: ${props =>
    props?.isDefault ? '1px solid #CDA257' : '1px solid #969696'};
  border-radius: 12px;
  cursor: pointer;
`;

export const DeliveryEstimation = styled.div`
  font-weight: 510;
  font-size: 13px;
  letter-spacing: 0.04em;
  color: #969696;
`;

export const DeliveryAddress = styled.div`
  margin-top: 1em;
  background: #ffffff;
  border-radius: 8px;
  padding: 1em;
`;

export const ProfileAddress = styled(DeliveryAddress)`
  padding: 0.5em;
  margin: 0;
`;

export const StyledImage = styled.img`
  max-width: 100%;
  height: 65px;
`;
