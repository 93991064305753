import {
  FILTER_REGION_PAYLOAD
} from './action.types';

export const filterRegionPayload = payload => dispatch => {
  dispatch({
    type: FILTER_REGION_PAYLOAD,
    payload: payload
  });
};

