import React from 'react';

import { servicesRequest } from '../../Utils';
import { PRODUCT_URL } from '../../Utils/serviceUrl.utils';
import ProductComponent from './Wine.component';

const WineContainer = ({ search = '' }) => {
  const [products, setProducts] = React.useState([]);
  const [allProducts, setAllProducts] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pagination, setPagination] = React.useState({
    page: '1',
    total_page: '1',
    total_item: '1',
    itemperpage: '100'
  });
  const [isLoading, setIsLoading] = React.useState(true);

  const fetching = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.post(PRODUCT_URL, {
        itemCode: '',
        itemName: search,
        region: '',
        appellation: '',
        country: '',
        itemPerPage: 100,
        pageCount: page,
        onlineStore: '1',
        sortByCountry: true
      });

      setProducts(data.products);
      setPagination(data.pagination);
    } catch (error) {}
  };

  const fetchingAllList = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const {
        data: { data }
      } = await authorizedRequest.post(PRODUCT_URL, {
        itemCode: '',
        itemName: '',
        region: '',
        appellation: '',
        country: '',
        itemPerPage: 10000,
        pageCount: 1,
        onlineStore: '1',
        sortByCountry: true
      });

      setAllProducts(data.products);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const props = {
    products,
    allProducts,
    setProducts,
    page,
    setPage,
    pagination,
    setPagination,
    isLoading
  };

  React.useEffect(() => {
    fetching().catch(console.error);
    fetchingAllList().catch(console.error);
  }, [page]);

  return <ProductComponent {...props} />;
};

export default WineContainer;
