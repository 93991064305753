import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { ProductCart } from '../ProductCart';
import { CartContainer, ColTitle } from '../../Screens/Cart/Cart.styled';
import { useTranslation } from 'react-i18next';

const ProductCartList = props => {
  const { carts, addToCart, deleteCart, moveToWishlist } = props;
  const { t } = useTranslation();

  const newProps = () => ({
    addToCart,
    deleteCart,
    moveToWishlist
  });

  return (
    <div>
      <Row>
        <CartContainer md={12} sm={12} xl={12}>
          <Row>
            <Col md={5} xs={5}>
              <ColTitle>{t('product-name')}</ColTitle>
            </Col>
            <Col md={7} xs={7}>
              <Row>
                <Col md={3} xs={3} className="d-flex justify-content-center">
                  <ColTitle>{t('price')}</ColTitle>
                </Col>
                <Col md={4} xs={4} className="d-flex justify-content-center">
                  <ColTitle>{t('qty')}</ColTitle>
                </Col>
                <Col md={2} xs={2} className="d-flex justify-content-center">
                  <ColTitle>{t('subtotal')}</ColTitle>
                </Col>
                <Col md={3} xs={3} className="d-flex justify-content-center">
                  <ColTitle>{t('action')}</ColTitle>
                </Col>
              </Row>
            </Col>
          </Row>
        </CartContainer>
      </Row>

      {carts.map((item, index) => (
        <ProductCart cart={item} key={index} {...newProps()} />
      ))}
    </div>
  );
};

export default ProductCartList;
