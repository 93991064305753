import React from 'react';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container, Section, SectionTitle } from './Event.styled';
import { EventList, Pagination } from '../../Components';
import { servicesRequest } from '../../Utils';
import { showNotification as showNotificationRedux } from '../../Redux/Actions/notification.action';
import { CONTENT_URL, EVENT_URL } from '../../Utils/serviceUrl.utils';

const EventContainer = () => {
  const [events, setEvents] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [bannerURL, setBannerURL] = React.useState([]);

  const pageSize = 8;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const showNotification = text =>
    dispatch(showNotificationRedux({ message: text }));

  const fetching = async () => {
    setLoading(true);
    try {
      const request = await servicesRequest();
      const {
        data: {
          data: { totalItems, contents }
        }
      } = await request.get(EVENT_URL, {
        params: {
          page: pages,
          size: pageSize,
          search
        }
      });

      setTotalItems(totalItems);
      setEvents(contents);
    } catch (error) {
      showNotification('Sorry, we unable to fetch event. Please try again');
    } finally {
      setLoading(false);
    }
  };

  const fetchBanner = async () => {
    try {
      const request = await servicesRequest();
      let {
        data: {
          data: { thumbnail }
        }
      } = await request.get(CONTENT_URL + '/detail', {
        params: {
          type: 'EVENT_BANNER'
        }
      });

      thumbnail = JSON.parse(thumbnail);
      setBannerURL(thumbnail);
    } catch (e) {}
  };

  React.useEffect(() => {
    fetchBanner().catch(console.error);
    fetching().catch(console.error);
  }, [pages]);

  return (
    <Container>
      <Section className="px-3 mt-5">
        <SectionTitle>{t('wine-tasting-diner')}</SectionTitle>
      </Section>

      <div className="p-3">
        {events?.length === 0 && loading ? (
          <ShimmerSimpleGallery row={1} col={4} imageHeight={200} caption />
        ) : (
          <EventList data={events} />
        )}
      </div>
      <Pagination
        data={{ total_item: totalItems, itemperpage: pageSize }}
        onChangePage={page => {
          setPages(page - 1);
        }}
      />
    </Container>
  );
};

export default EventContainer;
