import React from 'react';
import { useParams } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { get } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { servicesRequest } from '../../Utils';
import { EVENT_DETAIL_URL } from '../../Utils/serviceUrl.utils';
import {
  Banner,
  Container,
  DetailDate,
  DetailDateValue,
  RegisterButton,
  Title,
  CardEventContainer
} from './EventDetail.styled';
import RegisterComponent from './Components/RegisterComponent.js';

const EventDetailContainer = () => {
  const [event, setEvent] = React.useState(null);
  const [isRegister, setIsRegister] = React.useState(false);
  const { t } = useTranslation();

  const { id } = useParams();

  const fetching = async () => {
    try {
      const request = await servicesRequest();
      const {
        data: { data }
      } = await request.get(EVENT_DETAIL_URL + id);

      setEvent(data);
    } catch (e) {}
  };
  React.useEffect(() => {
    fetching().catch(console.error);
  }, []);

  const title = get(event, 'title', 'Event Title');
  const image = get(event, 'image', 'Event Image');
  const content = get(event, 'content', 'Event Description');
  const date = get(event, 'event_date', new Date());
  const time = get(event, 'event_time', new Date());
  const createdAt = get(event, 'createdAt', new Date());

  const now = moment();
  const then = moment(`${date} ${time}`);

  const isDisabled = Number(then.diff(now, 'hours')) <= 0;

  if (isRegister) {
    return (
      <RegisterComponent
        event={event}
        onSuccess={() => {
          setTimeout(() => {
            setIsRegister(false);
          }, [2000]);
        }}
      />
    );
  }

  return (
    <Container>
      <Banner src={image} alt="img-banner" className="img-fluid" />
      <CardEventContainer>
        <Col md={8}>
          <Title>{title}</Title>
          <div className="mt-4" dangerouslySetInnerHTML={{ __html: content }} />
        </Col>
        <Col>
          <div>
            <DetailDate className="text-center fw-bolder">
              {t('event-date')}
            </DetailDate>
            <DetailDateValue className="text-center">
              {moment(date).format('MMMM, Do YYYY')}
            </DetailDateValue>
          </div>
          <div className="mt-3">
            <DetailDate className="text-center fw-bolder">
              {t('event-time')}
            </DetailDate>
            <DetailDateValue className="text-center">
              {moment(time, 'HH:mm:ss').format('hh:mm A')}
            </DetailDateValue>
          </div>
          <div className="mt-3">
            <DetailDate className="text-center fw-bolder">
              {t('published-date')}
            </DetailDate>
            <DetailDateValue className="text-center">
              {moment(createdAt).format('MMMM, Do YYYY')}
            </DetailDateValue>
          </div>
          {!isDisabled && (
            <div className="text-center">
              <RegisterButton onClick={() => setIsRegister(true)}>
                {t('register-now')}
              </RegisterButton>
            </div>
          )}
        </Col>
      </CardEventContainer>
    </Container>
  );
};

export default EventDetailContainer;
