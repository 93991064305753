import React from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import {
  ContactContainer,
  ContactLabel,
  ContactValue,
  Description,
  Title,
  Content
} from './ContactUs.styled';
import { ContactUsForm } from '../../Components';
import { servicesRequest } from '../../Utils';
import { CONTACT_URL } from '../../Utils/serviceUrl.utils';
import { getHtmlContent } from '../../Utils/general.utils';

const ContactUsScreen = () => {
  const [contact, setContact] = React.useState(null);
  const [map, setMap] = React.useState(null);
  const [branch, setBranch] = React.useState(null);
  const [isInsideIframe, setIsInsideIframe] = React.useState(false);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const inIframe = window.self !== window.top;
    setIsInsideIframe(inIframe);
  }, []);

  const fetching = async () => {
    try {
      const authorizedRequest = await servicesRequest();
      const { data } = await authorizedRequest.get(CONTACT_URL);
      setContact(data.data);
      setBranch(JSON.parse(data.data.branch));
      setMap(updatedMapData);
    } catch (error) {}
  };

  const addQueryParam = (url, param, characterToAdd) => {
    const position = url.indexOf(param);
    if (position !== -1) {
      const updatedUrl =
        url.substring(0, position) + characterToAdd + url.substring(position);
      return updatedUrl;
    } else {
      return url;
    }
  };

  React.useEffect(() => {
    fetching();
  }, []);

  const updatedBranchData = branch?.map((item, index) => {
    const updatedMaps = item?.maps.replace('width="600"', 'width="100%"');
    return { ...item, maps: updatedMaps };
  });
  
  return (
    <React.Fragment>
      <ContactContainer>
        <Title>{t('contact-us')}</Title>
        {
          contact && <Description dangerouslySetInnerHTML={getHtmlContent(contact, i18n.language)} />

        }
        {/* <ContactLabel>{t('call-us')}</ContactLabel>
        <ContactValue>{contact?.phone}</ContactValue>
        <ContactLabel>{t('email')}</ContactLabel>
        <ContactValue>{contact?.email}</ContactValue>
        <ContactLabel>{t('visit-us')}</ContactLabel>
        <ContactValue>{contact?.address}</ContactValue> */}
        <Content
          className="mt-4"
          dangerouslySetInnerHTML={{
            __html: contact?.address_map.replace('width="600"', 'width="100%"')
          }}
        />
      </ContactContainer>
      <ContactContainer>
        {updatedBranchData?.map((item, index) => (
          <div key={index} className="mb-5">
            <Title>{item?.storeName}</Title>
            <ContactLabel>{t('contact')}</ContactLabel>
            <ContactValue>{item?.storeContact}</ContactValue>
            <ContactLabel>{t('address')}</ContactLabel>
            <ContactValue>{item?.address}</ContactValue>
            <ContactLabel>{t('working-hour')}</ContactLabel>
            <ContactValue>{item?.workHour}</ContactValue>
            <Content
              className="mt-4"
              dangerouslySetInnerHTML={{ __html: item?.maps }}
            />
          </div>
        ))}
      </ContactContainer>
      <ContactUsForm />
    </React.Fragment>
  );
};

export default ContactUsScreen;
