import {
  WEBSITE_LISTING_PAYLOAD
} from './action.types';

export const filterWebsiteListingPayload = payload => dispatch => {
  dispatch({
    type: WEBSITE_LISTING_PAYLOAD,
    payload: payload
  });
};

