export const COUNTER_TYPE = {
  ADD: 'ADD',
  SUBTRACT: 'SUBTRACT',
  SET: 'SET'
};

export const NEW_ROUTE = {
  home: 'home',
  en: 'en',
  enShop: 'en/shop',
  enContact: 'en/contact',
  enAbout: 'en/about-us'
};

export const ROUTE = {
  root: '',
  signIn: 'sign-in',
  signUp: 'sign-up',
  resetPassword: 'reset-password/:email/:otp',
  product: 'product',
  wine: 'wine',
  productDetail: 'product-detail/:product',
  blog: 'blog',
  blogDetail: 'blog/:id',
  images: 'images',
  imagesDetail: 'images/:id',
  news: 'news',
  newsDetail: 'news/:id',
  contactUs: 'contact-us',
  faq: 'faq',
  cart: 'cart',
  wishlist: 'wishlist',
  event: 'event',
  membership: 'membership',
  eventDetail: 'event/:id',
  portal: 'portal',
  activateAccount: 'activate-account/:email/:otp',
  account: 'account',
  aboutUs: 'about-us',
  returnPolicy: 'return-policy',
  termOfUse: 'term-of-use',
  privacyPolicy: 'privacy-policy',
  eprCompliance: 'epr-compliance',
  burgundy: 'burgundy',
  burgundyDetail: 'burgundy/:id',
  category: 'category/:mainCategory/:subCategory',
  search: 'search/:text',
  ...NEW_ROUTE
};

//WISHLIST TRANSACTION
export const TRANSACTION_TYPE = {
  ORIGIN: 'ORIGIN', //FROM WISHLIST
  EXTERNAL: 'EXTERNAL' //FROM CART
};

export const menu = [
  {
    label: 'About Us',
    value: 'ABOUT_US'
  },
  {
    label: 'Return Policy',
    value: 'RETURN_POLICY'
  },
  {
    label: 'Term of Use',
    value: 'TERM_OF_USE'
  },
  {
    label: 'Privacy Policy',
    value: 'PRIVACY_POLICY'
  },
  {
    label: 'EPR Compliance',
    value: 'EPR_COMPLIANCE'
  }
];

export const PAYMENT_TYPE = {
  CHEQUE: 'CHEQUE',
  CREDIT_CARD: 'CREDIT_CARD',
  BANK_TRANSFER: 'BANK_TRANSFER',
  CASH: 'CASH',
  PAYME: 'PAYME'
};

export const PAYMENT_STATUS = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  CANCELLED: 'CANCELLED',
  REFUNDED: 'REFUNDED'
};

export const DISTRICT = [
  {
    label: 'Hongkong Island',
    label_ch: '香港岛',
    value: 'Hongkong Island',
    status: 'IN'
  },
  {
    label: 'Kowloon',
    label_ch: '九龙',
    value: 'Kowloon',
    status: 'IN'
  },
  {
    label: 'New Territories',
    label_ch: '新界',
    value: 'New Territories',
    status: 'IN'
  },
  {
    label: 'Ma Wan',
    label_ch: '马湾',
    value: 'Ma Wan',
    status: 'OUT'
  },
  {
    label: 'Discovery Bay',
    label_ch: '愉景湾',
    value: 'Discovery Bay',
    status: 'OUT'
  },
  {
    label: 'Chek Lap Kok',
    label_ch: '赤鱲角',
    value: 'Chek Lap Kok',
    status: 'OUT'
  }
];

export const OUT_DISTRICT = ['Ma Wan', 'Discovery Bay', 'Chek Lap Kok'];
