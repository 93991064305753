import DOMPurify from 'dompurify';
import * as Yup from 'yup';
import Skeleton from 'react-loading-skeleton';
import React from 'react';
import jwt_decode from 'jwt-decode';
import { logoBase64, NotFoundImage } from '../Assets/Images';
import * as ExcelJS from 'exceljs';
import moment from 'moment';

export const sanitizeDOM = html => ({
  __html: DOMPurify.sanitize(html)
});

export const passwordAndConfirmSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password length should be at least 8 characters'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .min(8, 'Password length should be at least 8 characters')
    .oneOf([Yup.ref('password')], 'Passwords do not match')
});

export const _renderSkeleton = text => {
  return typeof text === 'string' ? text : '' || <Skeleton />;
};

export const removeTags = str => {
  if (str === null || str === '') return false;
  else str = str.toString();

  return str.replace(/(<([^>]+)>)/gi, '');
};

export const limitOutputString = (text, count, insertDots) => {
  return (
    text.slice(0, count) + (text.length > count && insertDots ? '...' : '')
  );
};

export const debounce = (func, wait, immediate) => {
  let timeout;

  return (...args) => {
    let context = this;

    let later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const checkJWT = () => {
  let isExpired = false;
  const token = localStorage.getItem('token') || '';

  if (token) {
    const decodedToken = jwt_decode(token);
    const dateNow = new Date();

    if (decodedToken.exp * 1000 < dateNow.getTime()) {
      isExpired = true;
    }
  } else {
    isExpired = true;
  }

  return isExpired;
};

export const numberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const reformatString = string => {
  return string
    .replaceAll('_', ' ')
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

export const checkValidURLImage = (string, defaultImage = NotFoundImage) => {
  if (string === undefined) return defaultImage;
  return string.includes('http') ? string : defaultImage;
};

export const exportToCSV = (apiData, fileName) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');

  // Set company logo in the header
  const logoImage = workbook.addImage({
    base64: logoBase64,
    extension: 'png'
  });

  const imageWidth = 300; // Set the desired width of the image
  const imageHeight = 154; // Set the desired height of the image

  worksheet.addImage(logoImage, {
    tl: { col: 1, row: 2 },
    ext: { width: imageWidth, height: imageHeight }
  });

  // Set address in a separate row
  worksheet.getCell('F3').value = 'T: +852 2882 7772 (JC Chan, Jason Ng)';
  worksheet.getCell('F4').value = 'T: +852 2512 8832 (Dicky Lee)';
  worksheet.getCell('F5').value = 'T: +852 2155 4248 (Jenny Cheung, Kit Choi)';
  worksheet.getCell('F6').value = 'Email: info@winebuff.hk';
  worksheet.getCell('F7').value =
    'Unit 1106, Block A, Sea View Estate, 2 Watson Road, North Point, HK';
  worksheet.getCell('F8').value =
    'Business Hour: 12:00-20:00 Mon - Sat (Sundays & Public Holidays are closed)';
  worksheet.getCell('F9').value =
    '6/F Yung Kee Building, 32-40 Wellington Street, Central, HK';
  worksheet.getCell('F10').value =
    'Business Hour: 12:00-22:00 Mon - Sat (Sundays & Public Holidays are closed)';
  worksheet.getCell('F11').value = moment().format('DD-MM-YYYY');
  worksheet.getCell('F11').font = { bold: true, size: 20 };

  // Insert empty rows for spacing
  worksheet.insertRow(12);
  worksheet.insertRow(13);

  const columns = [
    { header: 'Code Number', key: 'col1', width: 12 },
    { header: 'Price', key: 'col2', width: 10 },
    { header: 'Member Price', key: 'col3', width: 12 },
    { header: 'Vintage', key: 'col4', width: 10 },
    { header: 'Description', key: 'col5', width: 30 },
    { header: 'Qty', key: 'col6', width: 5 },
    { header: 'Size', key: 'col7', width: 10 },
    { header: 'Country', key: 'col8', width: 10 },
    { header: 'Region', key: 'col9', width: 10 },
    { header: 'Appellation', key: 'col10', width: 15 },
    { header: 'Rating', key: 'col11', width: 10 },
    { header: 'Category', key: 'col12', width: 10 }
  ];

  columns.forEach((column, index) => {
    worksheet.getColumn(index + 1).key = column.key;
    worksheet.getColumn(index + 1).width = column.width;
    worksheet.getCell(14, index + 1).value = column.header;
    worksheet.getCell(14, index + 1).font = { bold: true };
  });

  apiData.forEach((item, index) => {
    worksheet.addRow(item, `A${index + 15}`);
  });

  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
};

export const getContent = (data, language) => {
  if (language === 'cn' && data?.content_ch) {
    return data.content_ch;
  }
  return data?.content;
};

export const getHtmlContent = (data, language) => {
  const content =
    language === 'cn' && data?.content_ch ? data.content_ch : data?.content;
  return content ? { __html: sanitizeDOM(content).__html } : { __html: '' };
};

export const getTitle = (title, titleCh, language) => {
  if (language === 'cn' && titleCh) {
    return titleCh;
  }
  return title;
};
