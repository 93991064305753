import {
  ADD_CARTS,
  SUBTRACT_CARTS,
  DEFAULT_CARTS,
  ADD_CARTS_PAYLOAD,
  SUBTRACT_CARTS_PAYLOAD,
  RESET_CARTS_PAYLOAD
} from '../Actions/action.types';

const cartState = {
  total: 0,
  cartsContainer: JSON.stringify({
    carts: [],
    subTotal: 0,
    subTotalDiscount: 0,
    totalQuantity: 0
  })
};

const cartsReducer = (state = cartState, action) => {
  switch (action.type) {
    case DEFAULT_CARTS:
      return { ...state, total: action.payload };
    case ADD_CARTS:
      return { ...state, total: state.total + action.payload };
    case SUBTRACT_CARTS:
      return { ...state, total: state.total - action.payload };
    case ADD_CARTS_PAYLOAD:
      let tempCartsContainer = JSON.parse(state.cartsContainer);
      let { carts: tempCarts, subTotal, totalQuantity } = tempCartsContainer;

      // check if already in temp carts
      const findIfExists = tempCarts.findIndex(
        x => x.products.code === action.payload.products.code
      );

      if (findIfExists !== -1) {
        // if exists, exist carts will delete first
        let existsCarts = tempCarts[findIfExists];

        subTotal = Number(subTotal) - Number(existsCarts.subTotal);
        totalQuantity = Number(totalQuantity) - Number(existsCarts.qty);

        tempCarts.splice(findIfExists, 1);
      }

      //if not exists, add to carts
      tempCarts.push(action.payload);

      // now calculate for subTotal, subTotalDiscount, totalQuantity
      subTotal = Number(subTotal) + Number(action.payload.subTotal);
      totalQuantity = Number(totalQuantity) + Number(action.payload.qty);

      // next, set the new value of the state
      let finalCalculation = {
        carts: tempCarts,
        subTotal,
        subTotalDiscount: 0,
        totalQuantity
      };

      finalCalculation = JSON.stringify(finalCalculation);

      return { ...state, cartsContainer: finalCalculation };
    case SUBTRACT_CARTS_PAYLOAD:
      let temp =
        state.cartsContainer === '' ? [] : JSON.parse(state.cartsContainer);
      const itemToDelete = action.payload;

      const findIndexItem = temp.findIndex(x => x.code === itemToDelete.code);
      temp.splice(findIndexItem, 1);

      return {
        ...state,
        total: temp?.length,
        cartsContainer: JSON.stringify(temp)
      };
    case RESET_CARTS_PAYLOAD:
      return {
        ...state,
        cartsContainer: JSON.stringify({
          carts: [],
          subTotal: 0,
          subTotalDiscount: 0,
          totalQuantity: 0
        })
      };
    default:
      return state;
  }
};

export default cartsReducer;
