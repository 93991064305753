import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import {
  BorderedList,
  CardListContainer,
  ContentLabel,
  ContentLabelBold,
  ContentLabelHeader
} from '../ProductCard/ProductCard.styled';

const WineListMobile = ({ data }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <CardListContainer>
        <Row className="p-0 gy-0">
          <Col sm={9} xs={9}>
            <ContentLabelHeader>
              {data?.name_en ? data.name_en : '-'}
            </ContentLabelHeader>
          </Col>
        </Row>
        <BorderedList>
          <Col>
            <ContentLabelBold>{t('code-no')}</ContentLabelBold>
          </Col>
          <Col>
            <ContentLabel>{data?.code}</ContentLabel>
          </Col>
        </BorderedList>
        <BorderedList>
          <Col>
            <ContentLabelBold>{t('region')}</ContentLabelBold>
          </Col>
          <Col>
            <ContentLabel>{data?.region}</ContentLabel>
          </Col>
          <Col>
            <ContentLabelBold>{t('appellation')}</ContentLabelBold>
          </Col>
          <Col>
            <ContentLabel>{data?.appellation}</ContentLabel>
          </Col>
        </BorderedList>
        <BorderedList>
          <Col>
            <ContentLabelBold>{t('price')}</ContentLabelBold>
          </Col>
          <Col>
            <ContentLabel>{data?.bprice}</ContentLabel>
          </Col>
          {/*<Col>*/}
          {/*  <ContentLabelBold>Now</ContentLabelBold>*/}
          {/*</Col>*/}
          {/*<Col>*/}
          {/*  <ContentLabel>{data?.bprice}</ContentLabel>*/}
          {/*</Col>*/}
          <Col>
            <ContentLabelBold>{t('qty')}</ContentLabelBold>
          </Col>
          <Col>
            <ContentLabel>{data?.size}</ContentLabel>
          </Col>
        </BorderedList>
      </CardListContainer>
    </>
  );
};

export default WineListMobile;
