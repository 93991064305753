import React from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import propTypes from 'prop-types';

import { Icon } from '../index';
import { Container, Title } from './CollapseMenu.styled';

const CollapseMenuComponent = ({
  title,
  Component,
  collapse,
  type = 1,
  dataToHOC = {},
  onClick,
  submenuData
}) => {
  const [show, setShow] = React.useState(collapse);

  const handleClick = (event) => {
    setShow(!show);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <React.Fragment>
      <Container
        className="mt-4"
        onClick={handleClick}
        type={type}
        show={show}
        submenuData={submenuData}
      >
        <Title type={type}>{title}</Title>
        <Icon
          IconShow={show ? IoIosArrowUp : IoIosArrowDown}
          style={{ size: 20 }}
        />
      </Container>

      {show && <Component {...dataToHOC} />}
    </React.Fragment>
  );
};


export default CollapseMenuComponent;
