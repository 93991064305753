import React from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  ActionButton,
  ActionButtonContainer,
  Container,
  IconWishContainer,
  ProductDicountPrice,
  ProductImage,
  ProductImageContainer,
  ProductLabel,
  ProductNameEn,
  ProductNameContainer,
  ProductPrice,
  StyledLinkContainer,
  WishlistButton,
  WishListButtonContainer,
  ProductLabel2,
  ProductLabel3,
  ProductLabel2Container,
  ProductLabel2Container2
} from './ProductCard.styled';
import Icon from '../Icon';
import { StyledLink } from '../../Assets/GlobalStyled';
import {
  checkValidURLImage,
  numberWithCommas
} from '../../Utils/general.utils';

const ProductCardComponent = ({
  data,
  wishlists = [],
  carts = [],
  wishlistAction,
  cartAction,
  isWithWishlistButton = true
}) => {
  const [isWishlist, setIsWishlist] = React.useState(
    !wishlists?.includes(data?.code)
  );
  const [isCarts, setIsCarts] = React.useState(!carts?.includes(data?.code));
  const navigate = useNavigate();
  const parseData = JSON.parse(data.qty);
  const { t } = useTranslation();

  const unit =
    data?.website_listing === 'Accessories' ? t('pieces') : t('bottles');

  React.useEffect(() => {
    setIsWishlist(wishlists?.includes(data?.code));
    setIsCarts(carts?.includes(data?.code));
  }, [wishlists, data, carts]);

  const addToWishlist = async () => {
    try {
      await wishlistAction(data?.code);
      setIsWishlist(!isWishlist);
    } catch (error) {}
  };
  const addToCart = async () => {
    try {
      await cartAction(data);
      setIsCarts(!isCarts);
    } catch (error) {}
  };

  return (
    <Container>
      <ProductImageContainer>
        <ProductImage
          image={checkValidURLImage(data?.image)}
          onClick={() => {
            navigate(`/product-detail/${data?.code}`);
          }}>
          {isWithWishlistButton && (
            <WishListButtonContainer>
              <WishlistButton onClick={addToWishlist}>
                <IconWishContainer>
                  <Icon IconShow={isWishlist ? AiFillHeart : AiOutlineHeart} />
                </IconWishContainer>
              </WishlistButton>
            </WishListButtonContainer>
          )}
        </ProductImage>
        <ActionButtonContainer>
          <ActionButton
            className={isCarts ? 'disabled mx-1' : 'mx-1'}
            onClick={addToCart}>
            {t('add-to-cart')}
          </ActionButton>
        </ActionButtonContainer>
      </ProductImageContainer>
      <StyledLinkContainer className="text-center">
        <StyledLink to={`/product-detail/${data?.code}`}>
          <ProductNameContainer>
            <ProductNameEn>{data?.name_en}</ProductNameEn>
          </ProductNameContainer>
          <ProductPrice>HK$ {numberWithCommas(data?.bprice)} </ProductPrice>
          <ProductDicountPrice>
            <s>HK$ {numberWithCommas(data?.aprice)} </s>
            {/* <ProductCategory> - {data?.sub_category2_id}</ProductCategory> */}
          </ProductDicountPrice>
          <ProductLabel>
            {t('code-no')}&nbsp; {data?.code}{' '}
          </ProductLabel>
          <Row>
            <Col>
              <ProductLabel2Container2>
                <ProductLabel3 id="shopText">{t('central')}</ProductLabel3>
              </ProductLabel2Container2>
              <ProductLabel2Container>
                <ProductLabel>
                  {parseData.central > 0 ? parseData.central : 0} {unit}
                </ProductLabel>
              </ProductLabel2Container>
            </Col>
            <Col>
              <ProductLabel2Container>
                <ProductLabel2>{t('north-point')}</ProductLabel2>
              </ProductLabel2Container>
              <ProductLabel2Container>
                <ProductLabel>
                  {parseData.northPoint > 0 ? parseData.northPoint : 0} {unit}
                </ProductLabel>
              </ProductLabel2Container>
            </Col>
          </Row>
        </StyledLink>
      </StyledLinkContainer>
    </Container>
  );
};

export default ProductCardComponent;
