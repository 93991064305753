import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { AiOutlineDollar } from 'react-icons/ai';
import { isArray } from 'lodash';

import {
  PaymentItem,
  PaymentItemTitle,
  RoundedButton,
  StyledImage
} from '../Cart.styled';
import { Icon } from '../../../Components';
import { MasterCardLogo, PaymeLogo, VisaLogo } from '../../../Assets/Images';
import { PAYMENT_TYPE } from '../../../Constants';
import { DisplayFlexCenter } from '../../../Assets/GlobalStyled';

const style = {
  paymentIcon: {
    size: 30
  }
};

const payments = [
  {
    type: PAYMENT_TYPE.CREDIT_CARD,
    name: 'CREDIT/DEBIT CARD',
    img: [VisaLogo, MasterCardLogo]
  },
  {
    type: PAYMENT_TYPE.PAYME,
    name: 'PAYME',
    img: PaymeLogo
  }
];

const PaymentsComponent = props => {
  const [type, setType] = React.useState(props?.selectedPayments);

  let { paymentMethods } = props;

  paymentMethods = paymentMethods?.map(item => {
    return {
      type: item?.name,
      name: item?.name?.toUpperCase(),
      icon: AiOutlineDollar
    };
  });

  React.useEffect(() => {
    props.setSelectedPayments(type);
  }, [type]);

  const renderPaymentLogo = data => {
    if (data?.img) {
      if (isArray(data?.img)) {
        return (
          <DisplayFlexCenter>
            {data?.img?.map((image, index) => (
              <StyledImage key={index} alt="payment_method_logo" src={image} />
            ))}
          </DisplayFlexCenter>
        );
      }

      return <StyledImage alt="payment_method_logo" src={data.img} />;
    }
  };

  return (
    <Row className="mt-4 g-1">
      {payments.map((item, index) => (
        <Col
          key={index}
          xs={6}
          sm={6}
          md={3}
          className="align-items-center justify-content-center">
          <PaymentItem type={type === item.type}>
            <RoundedButton
              type={type === item.type}
              className="float-end"
              onClick={() => setType(item.type)}
            />
            <div className="mt-4">
              {item?.icon && (
                <Icon IconShow={item.icon} style={style.paymentIcon} />
              )}
              {renderPaymentLogo(item)}
              {!item?.img && <PaymentItemTitle>{item.name}</PaymentItemTitle>}
            </div>
          </PaymentItem>
        </Col>
      ))}

      {paymentMethods.map((item, index) => (
        <Col
          key={index}
          xs={12}
          sm={12}
          md={3}
          className="align-items-center justify-content-center">
          <PaymentItem type={type === item.type}>
            <RoundedButton
              type={type === item.type}
              className="float-end"
              onClick={() => setType(item.type)}
            />
            <div className="mt-4">
              {item?.icon && (
                <Icon IconShow={item.icon} style={style.paymentIcon} />
              )}
              {item?.img && (
                <img alt="payme_logo" src={item.img} className="img-fluid" />
              )}
              {!item?.img && <PaymentItemTitle>{item.name}</PaymentItemTitle>}
            </div>
          </PaymentItem>
        </Col>
      ))}
    </Row>
  );
};

export default PaymentsComponent;
