import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Col } from 'react-bootstrap';
import Ratio from 'react-bootstrap/Ratio';
import { SlHandbag } from 'react-icons/sl';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { filter } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

import { ROUTE } from '../../Constants';
import { servicesRequest } from '../../Utils';
import { CATEGORY_URL } from '../../Utils/serviceUrl.utils';
import { Goblet, WinebuffLogo } from '../../Assets/Images';
import Icon from '../Icon';
import './Navbar.styled.scss';
import { showHidePayload } from '../../Redux/Actions/showHide.action';
import { filterNewDataPayload } from '../../Redux/Actions/filterNewData.action';
import { filterAppellationPayload } from '../../Redux/Actions/filterAppellation.action';
import { filterCountryPayload } from '../../Redux/Actions/filterCountry.action';
import { filterCategoryPayload } from '../../Redux/Actions/filterCategory.action';
import { filterRegionPayload } from '../../Redux/Actions/filterRegion.action';
import { appellationDataPayload } from '../../Redux/Actions/appellationData.action';
import { filterWebsiteListingPayload } from '../../Redux/Actions/filterWebsiteListing.action';
import { selectedFilterValuePayload } from '../../Redux/Actions/selectedFilterValue.action';
import { filterSearchPayload } from '../../Redux/Actions/filterSearch.action';
import { filterActivePayload } from '../../Redux/Actions/filterActive.action';

import {
  BagBadgeMobile,
  CustomEmbed,
  CustomNavbarToggle,
  CustomRatio,
  CustomTopIconContainer,
  IconBagContainerMobile,
  MiddleIcon,
  MobileLogoContainer,
  MobileTopCornerContainer,
  NavbarHeaderText,
  NavbarLinkTitle3,
  NavbarLinkTitleDiv6,
  NavbarMobileContainer,
  RightIcon,
  RotatedIcon,
  RowContainer3,
  StyledButtonOutline2,
  StyledCol,
  StyledNavbar,
  StyledRow,
  TextContainer,
  TopIconBagMobile,
  TopNavbar
} from './Navbar.styled';
import filterData from './filterData.json';

const iconStyle = {
  color: 'white',
  className: 'global-class-name',
  size: '1.5em'
};

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
};

const NavbarMobile = props => {
  const [profileData, setProfileData] = React.useState(null);
  const [isLogin, setIsLogin] = React.useState(false);
  const location = useLocation();
  const [activeIndex, setActiveIndex] = React.useState(null);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const showHide = data => dispatch(showHidePayload(data));
  const filterNewData = data => dispatch(filterNewDataPayload(data));
  const filterAppellation = data => dispatch(filterAppellationPayload(data));
  const filterCountry = data => dispatch(filterCountryPayload(data));
  const filterCategory = data => dispatch(filterCategoryPayload(data));
  const filterRegion = data => dispatch(filterRegionPayload(data));
  const filterWebsiteListing = data =>
    dispatch(filterWebsiteListingPayload(data));
  const appellationData = data => dispatch(appellationDataPayload(data));
  const selectedFilterValue = data =>
    dispatch(selectedFilterValuePayload(data));
  const filterSearch = data => dispatch(filterSearchPayload(data));
  const filterActive = data => dispatch(filterActivePayload(data));

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  const isTablet = windowWidth > 768 && windowWidth <= 1024;

  const toggleSidebar = () => {
    showHide(true);
    navigate('/product');
  };

  const navigate = useNavigate();

  const { filterActive: filterActiveRedux } = useSelector(
    state => state.filterActiveReducer
  );

  React.useEffect(() => {
    const token = localStorage.getItem('token') || '';
    if (token) {
      const decodedToken = jwt_decode(token);
      setProfileData(decodedToken);
    }
  }, []);

  window.addEventListener('isLogin', () => {
    setIsLogin(true);
  });

  window.addEventListener('isLogout', () => {
    setIsLogin(false);
  });

  const handleClickActive = index => {
    setActiveIndex(index);
  };

  const filterNavbarCategory = category => {
    filterActive('TOP_FILTER');
    filterSearch('');
    selectedFilterValue('');
    appellationData(category.submenu);
    if (category.value === 'New Items') {
      filterNewData(true);
      clearFilter();
      navigate('/product');
    } else {
      resetFilter();
      filterWebsiteListing(category.label);
      filterNewData(false);
    }
  };
  const resetFilter = () => {
    selectedFilterValue('');
    filterCountry('');
    filterCategory('');
    filterAppellation('');
    filterRegion('');
    filterWebsiteListing('');
    filterSearch('');
    filterNewData(false);
  };
  const clearFilter = () => {
    selectedFilterValue('');
    filterCountry('');
    filterCategory('');
    filterAppellation('');
    filterRegion('');
    filterWebsiteListing('');
    filterSearch('');
  };

  const fetchRegionCategories = async () => {
    try {
      try {
        const authorizedRequest = await servicesRequest();
        const {
          data: { data }
        } = await authorizedRequest.get(CATEGORY_URL + '/region');
        let reformatSubCategory = data.map(item => {
          return {
            name: item.category_label,
            value: item.category_id,
            id: item.category_id
          };
        });

        const {
          data: { data: mainCategory }
        } = await authorizedRequest.post(CATEGORY_URL, { categoryCode: '' });
        let reformatMainCategory = filter(mainCategory, { type: 'I0' });

        reformatMainCategory = reformatMainCategory.map(item => {
          return {
            name: item.category_label,
            value: item.category_id,
            id: item.category_id,
            des: reformatSubCategory
          };
        });
      } catch (error) {}
    } catch (error) {}
  };
  React.useEffect(() => {
    fetchRegionCategories().catch(console.error);
  }, []);

  return (
    <NavbarMobileContainer className={'nav-mobile'}>
      <StyledNavbar collapseOnSelect expand="lg">
        <TopNavbar>
          <NavbarHeaderText>
            Welcome to Wine Buff | Explore the finest Wine with us
          </NavbarHeaderText>
        </TopNavbar>
        <StyledRow>
          <>
            <Col>
              <CustomNavbarToggle
                aria-controls={`offcanvasNavbar-expand-lg`}
                children={<RotatedIcon />}></CustomNavbarToggle>
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-lg`}
                aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                style={{
                  width: isMobile ? '85vw' : isTablet ? '40vw' : 'auto'
                }}
                placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                    <MobileLogoContainer>
                      <Ratio
                        aspectRatio="16x9"
                        onClick={() => {
                          navigate('/home');
                        }}>
                        <embed src={WinebuffLogo} className={'height-embed'} />
                      </Ratio>
                    </MobileLogoContainer>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="me-auto">
                    {isLogin || profileData ? (
                      <>
                        <Nav.Link
                          className="py-2"
                          href={props.redirectToLogin(ROUTE.account)}>
                          {t('my-account')}
                        </Nav.Link>
                      </>
                    ) : (
                      <>
                        <div>
                          <Nav.Link
                            className="py-2"
                            href={props.redirectToLogin(ROUTE.account)}>
                            {t('login-register')}
                          </Nav.Link>
                        </div>
                      </>
                    )}
                    <Nav.Link className="py-2" href="/about-us">
                      {t('about-us')}
                    </Nav.Link>
                    <Nav.Link className="py-2" href="/product">
                      {t('product')}
                    </Nav.Link>
                    <Nav.Link className="py-2" href="/event">
                      {t('event')}
                    </Nav.Link>
                    <Nav.Link className="py-2" href="/blog">
                      {t('blog')}
                    </Nav.Link>
                    <Nav.Link className="py-2" href="/burgundy">
                      {t('burgundy-map')}
                    </Nav.Link>
                  </div>

                  <div>
                    <Nav.Link href="/wine" className="py-2">
                      {t('wine-list')}
                    </Nav.Link>
                    <Nav.Link
                      href="https://newsletter.winebuff.hk/"
                      target="_blank"
                      className="py-2">
                      {t('signup-for-eNews')}
                    </Nav.Link>
                  </div>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Col>
            <Col>
              <Navbar href="#home" className="navbar-padding">
                <MobileLogoContainer>
                  <Ratio
                    aspectRatio="16x9"
                    onClick={() => {
                      navigate('/home');
                    }}>
                    <embed src={WinebuffLogo} className={'height-embed'} />
                  </Ratio>
                </MobileLogoContainer>
              </Navbar>
            </Col>
          </>
          <StyledCol>
            <div>
              <MobileTopCornerContainer>
                <CustomTopIconContainer>
                  <CustomRatio
                    aspectRatio="16x9"
                    onClick={() => {
                      navigate('/wine');
                    }}>
                    <CustomEmbed src={Goblet} className={'height-embed'} />
                  </CustomRatio>
                </CustomTopIconContainer>
                <TopIconBagMobile>
                  <IconBagContainerMobile>
                    <a href={props.redirectToLogin(ROUTE.cart, false)}>
                      <Icon style={iconStyle} IconShow={SlHandbag} />
                    </a>
                    <BagBadgeMobile>{props?.cartsTotal}</BagBadgeMobile>
                  </IconBagContainerMobile>
                </TopIconBagMobile>
              </MobileTopCornerContainer>
            </div>
          </StyledCol>
        </StyledRow>
      </StyledNavbar>
      {location.pathname === '/product' ||
      location.pathname === '/' ||
      location.pathname === '/en' ||
      location.pathname === '/en/shop' ||
      location.pathname.includes('/search/') ||
      location.pathname.includes('/category') ? (
        <>
          <RowContainer3 className="pt-3">
            <NavbarLinkTitleDiv6>
              {filterData.CATEGORIES?.map((category, index) => (
                <NavbarLinkTitle3
                  key={index}
                  onClick={() => {
                    filterNavbarCategory(category);
                    handleClickActive(index);
                  }}
                  className={
                    activeIndex === index && filterActiveRedux === 'TOP_FILTER'
                      ? 'active'
                      : ''
                  }>
                  {i18n.language === 'en'
                    ? category?.label
                    : category?.label_ch}
                </NavbarLinkTitle3>
              ))}
            </NavbarLinkTitleDiv6>
          </RowContainer3>
          <RowContainer3>
            <StyledButtonOutline2 onClick={toggleSidebar}>
              <TextContainer>
                <MiddleIcon />
                {t('search-wine')}
              </TextContainer>
              <RightIcon />
            </StyledButtonOutline2>
          </RowContainer3>
        </>
      ) : null}
    </NavbarMobileContainer>
  );
};

export default NavbarMobile;
