import React from 'react';
import { Product } from '../Product';
import { useParams } from 'react-router-dom';

const SearchContainer = () => {
  const { text } = useParams();

  return <Product search={text} />;
};

export default SearchContainer;
