import React from 'react';
import { Button, Checkbox, Select } from 'antd';
import { Controller, useForm } from 'react-hook-form';

import { SoftInput, SoftTextArea } from '../../SignIn/SignIn.styled';
import { DISTRICT } from '../../../Constants';
import { PlaceOrderButton } from '../Cart.styled';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

const ShippingDetailsComponent = props => {
  const [isEdit, setIsEdit] = React.useState(false);
  const { t, i18n } = useTranslation();

  const sanitizePhoneNumber = value => {
    return value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  };

  const { billingDetails, shippingDetails, setShippingDetails, setEditMode } =
    props;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phoneNumber: '',
      addressLine: '',
      district: DISTRICT[0].value
    }
  });

  const onSubmit = data => {
    setShippingDetails(data);
    setIsEdit(false);
    setEditMode(false);
  };

  const isInputDisabled = shippingDetails !== null && isEdit === false;

  const handleIfSameWithBillingDetails = () => {
    setValue('email', billingDetails?.email);
    setValue('name', billingDetails?.name);
    setValue('phoneNumber', billingDetails?.phoneNumber);
    setValue('addressLine', billingDetails?.addressLine);
    setValue('district', billingDetails?.district);
  };

  const _renderSubmitButton = () => {
    if (shippingDetails === null || isEdit) {
      return (
        <PlaceOrderButton onClick={handleSubmit(onSubmit)} className="mt-2">
          {t('next')}
        </PlaceOrderButton>
      );
    }
    return null;
  };

  const _renderEditButton = () => {
    if (shippingDetails !== null && !isEdit) {
      return (
        <Button
          className="mt-1"
          onClick={() => {
            setIsEdit(true);
            setEditMode(true);
          }}>
          {t('edit')}
        </Button>
      );
    }
  };

  return (
    <div className="mt-2">
      <Checkbox onClick={handleIfSameWithBillingDetails} className="mb-2">
        {t('same-as-billing')}
      </Checkbox>
      <Controller
        name="email"
        control={control}
        rules={{
          required: '*Email is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: '*Invalid email address'
          }
        }}
        render={({ field }) => (
          <div>
            <SoftInput
              {...field}
              type="text"
              placeholder={`*${t('email')}`}
              disabled={isInputDisabled}
              className={errors.email ? 'error' : 'mb-2'}
            />
            {errors.email && (
              <div className="error-message">{errors.email.message}</div>
            )}
          </div>
        )}
      />

      <Controller
        name="name"
        control={control}
        rules={{ required: '*Name is required' }}
        render={({ field }) => (
          <div>
            <SoftInput
              {...field}
              type="text"
              placeholder={`*${t('name')}`}
              disabled={isInputDisabled}
              className={errors.name ? 'error' : 'mb-2'}
            />
            {errors.name && (
              <div className="error-message">{errors.name.message}</div>
            )}
          </div>
        )}
      />

      <Controller
        name="phoneNumber"
        control={control}
        rules={{
          required: '*Phone number is required',
          pattern: {
            value: /^[0-9]+$/,
            message: '*Phone number must be numeric'
          }
        }}
        render={({ field }) => (
          <div>
            <SoftInput
              {...field}
              type="text"
              placeholder={`*${t('phone')}`}
              disabled={isInputDisabled}
              className={errors.phoneNumber ? 'error' : 'mb-2'}
              onChange={e => {
                const sanitizedValue = sanitizePhoneNumber(e.target.value);
                field.onChange(sanitizedValue);
              }}
            />
            {errors.phoneNumber && (
              <div className="error-message">{errors.phoneNumber.message}</div>
            )}
          </div>
        )}
      />

      <Controller
        name="addressLine"
        control={control}
        rules={{ required: '*Address is required' }}
        render={({ field }) => (
          <div>
            <SoftTextArea
              {...field}
              type="text"
              placeholder={`*${t('address')}`}
              disabled={isInputDisabled}
              className={errors.addressLine ? 'error' : 'mb-2'}
            />
            {errors.addressLine && (
              <div className="error-message">{errors.addressLine.message}</div>
            )}
          </div>
        )}
      />

      <Controller
        name="district"
        control={control}
        rules={{ required: '*District is required' }}
        render={({ field }) => (
          <div>
            <Select
              {...field}
              style={{ width: '100%' }}
              options={DISTRICT.map(option => ({
                ...option,
                label: i18n.language === 'en' ? option.label : option.label_ch
              }))}
              disabled={isInputDisabled}
              className={errors.district ? 'error' : 'mb-2'}
            />
            {errors.district && (
              <div className="error-message">{errors.district.message}</div>
            )}
          </div>
        )}
      />

      {_renderSubmitButton()}
      {_renderEditButton()}
    </div>
  );
};

export default ShippingDetailsComponent;
