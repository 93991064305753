import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiCreditCard } from 'react-icons/bi';
import { BsHeart, BsHeartFill } from 'react-icons/bs';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  AddToBagButton,
  AddToWishlistButton,
  Container,
  Counter,
  Description,
  DescriptionDetail,
  Divider,
  Image,
  PickupContainer,
  PickupItem,
  PickupStock,
  PickupStore,
  PlusMinus,
  ProductContainer,
  ProductDescription,
  ProductFeatures,
  ProductName,
  ProductPriceContainer,
  ProductPriceDiscount,
  ProductPriceNormal,
  QuantityContainer,
  QuantityLabel,
  StyledInput
} from './ProductDetail.styled';
import {
  _renderSkeleton,
  checkValidURLImage,
  numberWithCommas
} from '../../Utils/general.utils';
import { COUNTER_TYPE } from '../../Constants';

const ProductDetailComponent = props => {
  const {
    handleWishlist,
    isWishlist,
    _counterQuantity,
    _setCounterQuantity,
    addToCart
  } = props;

  const { t } = useTranslation();

  const product = get(props, 'product', {});

  const quantity = get(props, 'quantity', 0);

  const name = get(product, 'name_en', 'Product Name');
  const code = get(product, 'code', 0);
  const price = get(product, 'bprice', 0);
  const originalPrice = get(product, 'aprice', 0);
  const category = get(product, 'category_1', 0);
  const vintage = get(product, 'vintage', 0);
  const appellation = get(product, 'appellation', 0);
  const format = get(product, 'size', 750);
  const region = get(product, 'region', 0);
  const country = get(product, 'country', 0);
  const rating = get(product, 'rating', 0);
  const description = get(product, 'description', '');
  const qtyLoc = get(props, 'product.qty', {});

  const unit =
    product?.category_1 === 'Accessories' ? t('pieces') : t('bottles');

  return (
    <Container>
      <Row className="mb-5">
        <Col xl={6} md={6} sm={12}>
          <Image
            src={checkValidURLImage(product?.image)}
            className="img-fluid"
          />
        </Col>
        <Col xl={6} md={6} sm={12}>
          <ProductName>{_renderSkeleton(name)}</ProductName>
          <ProductPriceContainer>
            <ProductPriceDiscount>
              HK${numberWithCommas(price)}
            </ProductPriceDiscount>
            <ProductPriceNormal>
              HK${numberWithCommas(originalPrice)}
            </ProductPriceNormal>
          </ProductPriceContainer>
          <QuantityContainer>
            <Counter>
              <QuantityLabel>{t('qty')}:</QuantityLabel>
              <PlusMinus
                onClick={() => _counterQuantity(COUNTER_TYPE.SUBTRACT)}>
                -
              </PlusMinus>
              <StyledInput
                value={quantity}
                onChange={e =>
                  _setCounterQuantity(COUNTER_TYPE.SET, e.target.value)
                }
              />
              <PlusMinus onClick={() => _counterQuantity(COUNTER_TYPE.ADD)}>
                +
              </PlusMinus>
            </Counter>
          </QuantityContainer>
          <PickupContainer>
            <PickupItem>
              <PickupStore>{`${t('central')}`}: </PickupStore>
              <PickupStock storeStock={Number(qtyLoc?.central)}>
                {Number(qtyLoc?.central) < 0 ? 0 : qtyLoc?.central} {unit}
              </PickupStock>
            </PickupItem>

            <PickupItem>
              <PickupStore>{`${t('north-point')}`}: </PickupStore>
              <PickupStock storeStock={Number(qtyLoc?.northPoint)}>
                {Number(qtyLoc?.northPoint) < 0 ? 0 : qtyLoc?.northPoint} {unit}
              </PickupStock>
            </PickupItem>
          </PickupContainer>

          <Row className="mt-5">
            <Col xs={8}>
              <AddToBagButton onClick={addToCart}>{`${t(
                'add-to-cart'
              )}`}</AddToBagButton>
            </Col>
            <Col xs={4}>
              <AddToWishlistButton onClick={handleWishlist}>
                {isWishlist ? <BsHeartFill /> : <BsHeart />}
              </AddToWishlistButton>
            </Col>
          </Row>
          <div className="mt-5">
            <ProductContainer>
              <BiCreditCard />
              <ProductFeatures> {`${t('scured-payments')}`}</ProductFeatures>
            </ProductContainer>
          </div>
        </Col>
      </Row>
      <Description>{`${t('product-description')}`}</Description>
      <Divider />
      <Row>
        <Col xs={5} md={2}>
          <DescriptionDetail>{`${t('product-code')}`}</DescriptionDetail>
        </Col>
        <Col xs={7} md={9}>
          <DescriptionDetail>{_renderSkeleton(code)}</DescriptionDetail>
        </Col>

        <Col xs={5} md={2}>
          <DescriptionDetail>{`${t('category')}`}</DescriptionDetail>
        </Col>
        <Col xs={7} md={9}>
          <DescriptionDetail>{_renderSkeleton(category)}</DescriptionDetail>
        </Col>

        <Col xs={5} md={2}>
          <DescriptionDetail>{`${t('vintage')}`}</DescriptionDetail>
        </Col>
        <Col xs={7} md={9}>
          <DescriptionDetail>{_renderSkeleton(vintage)}</DescriptionDetail>
        </Col>

        <Col xs={5} md={2}>
          <DescriptionDetail>{`${t('appellation')}`}</DescriptionDetail>
        </Col>
        <Col xs={7} md={9}>
          <DescriptionDetail>{_renderSkeleton(appellation)}</DescriptionDetail>
        </Col>

        <Col xs={5} md={2}>
          <DescriptionDetail>{`${t('format')}`}</DescriptionDetail>
        </Col>
        <Col xs={7} md={9}>
          <DescriptionDetail>{_renderSkeleton(format)}</DescriptionDetail>
        </Col>

        <Col xs={5} md={2}>
          <DescriptionDetail>{`${t('region')}`}</DescriptionDetail>
        </Col>
        <Col xs={7} md={9}>
          <DescriptionDetail>{_renderSkeleton(region)}</DescriptionDetail>
        </Col>

        <Col xs={5} md={2}>
          <DescriptionDetail>{`${t('country')}`}</DescriptionDetail>
        </Col>
        <Col xs={7} md={9}>
          <DescriptionDetail>{_renderSkeleton(country)}</DescriptionDetail>
        </Col>

        <Col xs={5} md={2}>
          <DescriptionDetail>{`${t('rating')}`}</DescriptionDetail>
        </Col>
        <Col xs={7} md={9}>
          <DescriptionDetail>{_renderSkeleton(rating)}</DescriptionDetail>
        </Col>
      </Row>
      <ProductDescription>{description}</ProductDescription>
    </Container>
  );
};

export default ProductDetailComponent;
