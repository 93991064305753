import React from 'react';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';

import { GLOBAL_PAY_CREDENTIALS, sign } from '../../Utils/globalPay.utils';

const { globalPayURL, accessKey, profileId } = GLOBAL_PAY_CREDENTIALS;

const GlobalPay = () => {
  const { purchaseId, total } = useParams();

  let payload;
  const referenceNumber = new Date().getTime();
  const url = `${globalPayURL}/pay`;

  const signed_date_time = moment(new Date(), 'yyyy-MM-DDThh:mm:ssZ')
    .tz('UTC')
    .format();

  payload = {
    access_key: accessKey,
    profile_id: profileId,
    transaction_uuid: purchaseId,
    signed_field_names:
      'access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency',
    unsigned_field_names: '',
    signed_date_time: signed_date_time,
    locale: 'en',
    transaction_type: 'authorization',
    reference_number: referenceNumber,
    amount: total,
    currency: 'HKD'
  };

  React.useEffect(() => {
    document.getElementById('submit').click();
  }, []);

  payload.signature = sign(payload);

  let input = [];

  for (const [key, value] of Object.entries(payload)) {
    const inputItem = `<input id='${key}' name='${key}' value='${value}' />`;
    input.push(inputItem);
  }

  return (
    <div hidden={true}>
      <form id="payment_confirmation" action={url} method="post">
        {input.map(item => (
          <div dangerouslySetInnerHTML={{ __html: item }} />
        ))}
        <input type="submit" id="submit" value="Confirm" />
      </form>
    </div>
  );
};

export default GlobalPay;
