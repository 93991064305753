import {
  FILTER_SEARCH_PAYLOAD
} from './action.types';

export const filterSearchPayload = payload => dispatch => {
  dispatch({
    type: FILTER_SEARCH_PAYLOAD,
    payload: payload
  });
};

