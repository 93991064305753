import {
  APPLLATION_DATA_PAYLOAD
} from './action.types';

export const appellationDataPayload = payload => dispatch => {
  dispatch({
    type: APPLLATION_DATA_PAYLOAD,
    payload: payload
  });
};

