import styled, { css } from 'styled-components';
import { DisplayFlex } from '../../Assets/GlobalStyled';
import { DropdownButton } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
const { Panel } = Collapse;
import { Collapse } from 'antd';
import { FiSearch } from 'react-icons/fi';
import { FiX } from 'react-icons/fi';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import { desktop, laptop, mobile, tablet } from '../../Assets/layout';


export const Container = styled.div`
  padding: 15px;
  ${mobile(css`
    padding: 15px;
  `)};
`;

export const CategoryTitle = styled.h1`
  font-weight: 600;
  font-size: 34px;
  line-height: 44px;
  color: #cda257;
`;

export const TopFilter = styled(DisplayFlex)`
  justify-content: space-between;
`;

export const FilterLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #171520;
  margin: 0;
`;

export const FilterFlex = styled(DisplayFlex)`
  gap: 1em;
`;

export const DropdownBtn = styled(DropdownButton)`
  background: #f1f1f1 !important;
  border-radius: 4px;
`;

export const TopIconContainer = styled.div`
 display: flex;
    margin-left: 20px;
 
`;

export const TopIconFilter = styled.div`
  margin-left: 5px;
  cursor: pointer;
  margin-bottom: 2px;
  padding-right: 10px;
`;

export const TopIconFilterLeft = styled.div`
  margin-left: 0px;
  cursor: pointer;
  margin-bottom: 2px;
`;

export const ListContainer = styled.div`
  ${desktop(css`
    display: none;
  `)};

  ${laptop(css`
    display: none;
  `)};

  ${tablet(css`
    display: none;
  `)};

  ${mobile(css`
    display: block;
  `)};
`;

export const ListTableContainer = styled.div`
  ${desktop(css`
    display: block;
  `)};

  ${laptop(css`
    display: block;
  `)};

  ${tablet(css`
    display: block;
  `)};

  ${mobile(css`
    display: none;
  `)};
`;

export const FilterTopContainer = styled(Row)`
  width: 100vw;
`;

export const FilterTopLeftContainer = styled(Col)`
  ${desktop(css`
    display: flex;
    justify-content: end;
  `)};

  ${laptop(css`
    display: flex;
    justify-content: end;
  `)};

  ${tablet(css`
    display: flex;
    justify-content: end;
  `)};

  ${mobile(css`
    padding-top: 10px;
    display: flex;
    justify-content: start;
  `)};
`;

export const SideBarContainer = styled(Col)`
  ${tablet(css`
    display: none;
  `)};

  ${laptop(css`
    display: none;
  `)};

  ${mobile(css`
    display: none;
  `)};
`;

export const ShowFilterContainer = styled.div`
  padding-left: 30px;
`;

export const TotalItemContainer = styled.div`
  ${mobile(css`
    display: none;
  `)};
`;

export const CollapsMenuContainer = styled.div`
  ${mobile(css`
    display: block;
  `)};
`;

export const CategoryLabel = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;

  color: #cda257;
`;

export const RadioFilterContainer = styled.span`
  padding-left: 35px;
`;

export const CustomCollapse = styled(Collapse)`
  background: transparent;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0px;
`;

export const CustomPanel = styled(Panel)`
  border-bottom: 1px solid black;
  border-radius: 0px;
`;

export const CustomHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const InputContainer2 = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
  background: #f9fafb;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 0 20px;
  height: 50px;
`;
export const InputContainer3 = styled.div`
  padding: 0 10px 20px 10px;
`;

export const MiddleIcon = styled(FiSearch)`
  margin-right: 10px;
  color: grey;
`;

export const CloseIcon = styled(FiX)`
  font-size: 25px;
  color: #cda257;
`;

export const Input = styled.input`
  padding: 2vh 0;
  outline: 0;
  color: #000000;
  font-size: 17px;
  width: 100%;
  background-color: transparent;
  border: none;

  ::-webkit-input-placeholder {
    color: #000000;
    opacity: 0.8;
  }
  :-ms-input-placeholder {
    color: #000000;
    opacity: 0.8;
  }
`;

export const SidebarHeader = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 125%;
  color: #cda257;
`;

export const CustomOffcanvas = styled(Navbar.Offcanvas)`
  width: 50vw; // Set the desired width using vw
`;

export const ProductsGrid = styled.div`
  display: grid;
   grid-gap: 20px; /* Add spacing between grid items */

  ${laptop`
    grid-template-columns: repeat(4, 1fr); /* 3 items per row in tablet view */
  `}

  ${desktop`
    grid-template-columns: repeat(4, 1fr); /* 3 items per row in tablet view */
  `}

  ${tablet`
    grid-template-columns: repeat(3, 1fr); /* 3 items per row in tablet view */
  `}

  ${mobile`
    grid-template-columns: repeat(2, 1fr); /* 2 items per row in mobile view */
  `}
`;